import React, { Component } from "react";
import Login from "./pages/Login"  
import Layout from "./Layout";

export default class App extends Component {

    render() {

        if(window.location.pathname !== "/") {
            return <Layout />
        } else {
            return <Login />
        }
        
    }

}
