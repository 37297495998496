import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import SelectClasse from '../SelectClasse'

export default class StudentYear extends Component {

    constructor(props) {
        super(props)
        this.state = {
            studentYears: [],
            classeYears: [],
            students: [],

            add: false,
            edit: false,
            id: null,
            student_id: null,
            classe_id: null,

            anchorEl: [],   
            loading: true,
            severity: "",
            message: "",
            isAlert:false
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Inscriptions"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/student-years', config)
        .then(response => {
            this.setState({ 
                studentYears: response.data.data,
                classeYears: response.data.classeYears,
                students: response.data.students,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){ this.setState({isAlert:false})}

    handleChangeClasse(data){
        if(data.name === 'classe_id'){
            this.setState({classe_id: data.value }) 
        }        
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({loading: true})   

        let item = this.state.classeYears.filter(c => c["classe_id"] === this.state.classe_id)[0]

        let elem = this.state.students.filter(c => c["id"] === this.state.student_id)[0]

        const bodyParameters = {
            year_id: this.props.year_id,
            student_id: this.state.student_id,
            classe_id: this.state.classe_id,
            cycle_id: item.cycle_id,
            user_id: elem.user_id
        }        

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/student-years',
            bodyParameters,
            config
        ).then(response => {          
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    add: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()            
            }                       
        }).catch(error => {
            this.setState({
                add: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    delete(id){
        this.setState({loading: true}) 

        let item = this.state.studentYears.filter(c => c["id"] === id)[0]

        const bodyParameters = {
            year_id: this.props.year_id,
            student_id: item.student_id,
            classe_id: this.state.classe_id
        }      

        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        axios.post(MainUrl() + '/api/v1/student-year-delete/' + id,
            bodyParameters,
            config
        ).then(response => {
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.componentDidMount()     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {    

        const {
            studentYears,
            classeYears,
            students,
            add,
            edit,
            classe_id,
            student_id,
            anchorEl,
            loading,
            severity,
            message,
            isAlert,
        } = this.state

        const column = [
            { 
                field: 'matricule', 
                headerName: 'Matricule',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.user.matricule}
            },
            { 
                field: 'sexe', 
                headerName: 'Sexe',
                maxWidth: 60,
                align:'center',
                renderCell: (cellValues) => { return <strong>{cellValues.row.student.sexe}</strong>}
            },
            { 
                field: 'prenom', 
                headerName: 'Prénom',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.user.first_name}
            },
            { 
                field: 'nom', 
                headerName: 'Nom',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.user.second_name}
            },
            { 
                field: 'classe', 
                headerName: 'Classe',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.classe.name}
            },
            { 
                field: 'name', 
                headerName: 'Cycle',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.cycle.name}
            },
            { 
                field: 'year', 
                headerName: 'Année académique',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.year.name}
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                        width:150
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem 
                                    style={{color:"red"}}
                                    onClick={() => {
                                        if(window.confirm("Êtes-vous de vouloir déinscrire cet(te) étudiant(e) dans cette classe ?"))
                                        {this.delete(cellValues.row.id)}
                                    }}
                                >
                                    <ListItemIcon><RemoveCircleOutlineIcon fontSize="small" style={{color:"red"}} /></ListItemIcon>Déinscrire
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    )
                }
            }
        ]

        let studentYear = []
        let studentAddYear = []
        if(this.props.year_id !== null){
            studentAddYear = studentYears.filter(c => c["year_id"] === this.props.year_id)
            studentYear = studentAddYear.filter(c => c["classe_id"] === classe_id)
        }

        let student = students
        studentAddYear.map((elem, index) => {
            student = student.filter(c => c["id"] !== elem.student_id)
        })

        if(this.props.search !== ''){
            let result = []
            studentYear.map((elem, index) => {
                elem.search = elem.user.matricule + ' ' + elem.user.first_name + ' ' + elem.user.second_name + ' ' + elem.sexe
                result = result.concat(elem)
            })
            studentYear = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        let classeYear = []
        if(classe_id !== null){
            classeYear = classeYears.filter(c => c["classe_id"] === classe_id)
        }

        return(
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    <SelectClasse year_id={this.props.year_id} onChange={this.handleChangeClasse.bind(this)} width={200} mr={2} />

                    <Button 
                        variant="contained" 
                        disabled={classe_id === null} 
                        onClick={() => this.setState({add: true})} 
                        sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff'}}
                    >
                        Inscrire un(e) Étudiant(e)
                    </Button>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={studentYear}
                            columns={column}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: { labelRowsPerPage: 'Ligne par page' }
                            }}
                        />
                    </Card>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Inscrire un(e) étudiant(e)
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Étudiant"
                                        name="student_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                    >
                                        {student.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.user.matricule} | {option.user.first_name} {option.user.second_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        disabled
                                        required
                                        select
                                        label="Classe"
                                        name="classe_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={classe_id || ''}
                                    >
                                        {classeYear.map((option) => (
                                            <MenuItem key={option.classe_id} value={option.classe_id}>
                                                {option.classe.name}  {option.cycle.name} | {option.year.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour l'inscrire
                        </Button>   
                    </form>

                </Dialog>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}