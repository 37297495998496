import React, { Component } from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class AlertNotification extends Component {

    handleChange(){
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    render() {

        return (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.props.isAlert}
                onClose={this.handleChange.bind(this)}
                autoHideDuration={6000}
            >
                <Alert 
                    severity={this.props.severity} 
                    sx={{ width: '100%' }} 
                    onClose={this.handleChange.bind(this)}
                >
                    {this.props.message}
                </Alert>
            </Snackbar>
        )

    }
}
