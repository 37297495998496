import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import axios from 'axios';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'

export default class Profil extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: null,
            first_name: "",
            second_name: "",
            password: "",
            passwordc:"",
            token: "",
            message: "",            

            showPassword: false,
            showPasswordc: false,

            severity: "",
            isAlert:false,
            loading: true,       
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Profil"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/get-user', config)
        .then(response => {
            const data = response.data.data       
            this.setState({ 
                id: data.id,
                first_name: data.first_name,
                second_name: data.second_name,                
                token: token,
                loading: false,
            });

        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault()
        if(this.state.password !== this.state.passwordc){
            this.setState({ 
                loading: false,
                severity: 'error',
                message: "Les deux mots de passe ne sont pas identiques",
                isAlert: true
            })
        }else{
            this.setState({loading: true})   
            const bodyParameters = {
                first_name: this.state.first_name,
                second_name: this.state.second_name,
                password: this.state.password,
            }
            const token = this.state.token        
            const config = {
                headers: {'Authorization': 'Bearer '+token}
            }
            axios.put(MainUrl() + '/api/v1/profil/' + this.state.id,
                bodyParameters,
                config
            ).then(response => {    
                if(response.data.statusCode ===201){
                    this.setState({
                        loading: false,
                        severity: 'error',
                        message: response.data.message,
                        isAlert: true
                    }) 
                } else{
                    this.setState({
                        loading: false,
                        severity: 'success',
                        message: response.data.message,
                        isAlert: true
                    }) 
                    this.componentDidMount()     
                    localStorage.setItem("USER", JSON.stringify(response.data.data));
                    this.props.onChange()  
                }                       
            }).catch(error => {
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: error.response.data?.message || "Une erreur inattendue s'est produite",
                    isAlert: true
                });
            });

        }
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    render(){

        const {
            first_name,
            second_name,
            matricule,
            password,
            passwordc,

            showPassword,
            showPasswordc,
            loading,
            severity,
            message,
            isAlert
        } = this.state
        
        return(
            <Grid container spacing={3}>
                <Grid item xs={12} display="flex" direction="column" justifyContent="center" alignItems="center" >
                    <Avatar 
                        sx={{
                            boxShadow: 12, 
                            color: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                            backgroundColor: "primary.main",
                            width: 80, height: 80
                        }} 
                        elevation={10}
                    >
                        <span style={{fontSize:40}}>{first_name.charAt(0)}{second_name.charAt(0)}</span>
                    </Avatar>

                    <h4 style={{marginTop:16}}>Bienvenue {first_name + " " + second_name}</h4>

                    <p style={{marginTop: 16, color:"gray"}}>Gérez vos informations, ainsi que la confidentialité et la sécurité de vos données.</p>    
                </Grid>

                <Grid item xs={12} sm={12} md={3} />

                <Grid item xs={12} sm={12} md={6}>
                    <Card elevation={6}  sx={{p:4, backgroundColor:'#ffffff', borderRadius:2}}>
                        <Box component="form" noValidate onSubmit={this.handleSubmit.bind(this)}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Prénom"
                                        name="first_name"
                                        style={{ width: '100%' }}
                                        value={first_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de famille"
                                        name="second_name"
                                        style={{ width: '100%' }}
                                        value={second_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="password"
                                        label="Nouveau mot de passe"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password || ""}
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPassword:! showPassword})} 
                                                    >
                                                        {showPassword ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="passwordc"
                                        label="Mot de passe confirmation"
                                        type={showPasswordc ? 'text' : 'password'}
                                        value={passwordc || ""}
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPasswordc:! showPasswordc})} 
                                                    >
                                                        {showPasswordc ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Button  
                                type="submit" 
                                fullWidth
                                variant="contained" 
                                elevation={10}
                                sx={{mt:3, height:40, boxShadow: 10, borderRadius:2, bgcolor: '#306bff' }}
                                endIcon={ loading ? <CircularProgress size={24} ml={2} sx={{color:'white'}} value={100} /> : null}
                                disabled={loading || (passwordc==="" || password==="")}
                            >
                                Enregistrer
                            </Button>   
                        </Box>
                    </Card>
                </Grid>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}