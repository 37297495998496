import React, { Component } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default class BackdropLoader extends Component { 

    render() {

        return (
            <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={this.props.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )

    }
}
