import React, { Component } from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import SelectClasse from '../SelectClasse'
import SelectComposition  from '../SelectComposition'

export default class Notes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notes: [],
            subjectsYears: [],

            classe_id: null,
            composition_id: null,

            anchorEl: [],   
            loading: true,
            severity: "",
            message: "",
            isAlert:false
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Notes de composition"
        this.setState({loading: false}) 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    handleChangeClasse(data){
        if(data.name === 'classe_id'){
            this.setState({ classe_id: data.value }) 
        }        
    }

    handleChangeComposition(data){
        if(data.name === 'composition_id'){
            this.setState({ 
                composition_id: data.value,
                loading: true,
            }) 
            this.getData(data.value)
        }        
    }

    getData(id){ 
        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: this.state.classe_id,
            composition_id: id
        }
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/notes', 
            bodyParameters, 
            config
        )
        .then(response => {
            this.setState({ 
                notes: response.data.data,
                subjectsYears: response.data.subjectsYears,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    render() {    

        const {
            notes,
            subjectsYears,
            classe_id,
            loading,
            severity,
            message,
            isAlert,
        } = this.state
        
        let subject = []
        for (let i = 0; i < subjectsYears.length; i++) {
            let marks = notes.filter(c => c["subject_year_id"] === subjectsYears[i].id)
            subject[i] = subjectsYears[i]
            subject[i].marks = marks
        }

        let listMG = []
        let listSTUDIENT= []
        if(subject.length > 0){
        
        subject[0].marks.map((element, index) => {   
            let student_id = element.student_id                  
            let COEF = 0
            let MCOEF  = 0
            let MG = 0
            subjectsYears.map((element, index) => {
                let mark =  element.marks.filter(c => c["student_id"] === student_id)[0]
                        
                let moyen_general = (mark.moyen_compo + mark.moyen_classe)/3

                let moyen_coef = moyen_general * mark.coef

                COEF = COEF + mark.coef

                MCOEF = MCOEF + moyen_coef                  
            })
                MG = MCOEF/COEF

                listMG = listMG.concat(MG)

                listSTUDIENT = listSTUDIENT.concat({
                    student_id : student_id,
                    mg: MCOEF/COEF
                })        
        })}

        const ranking = (arr) => arr.map((x, y, z) => z.filter((w) => w > x).length + 1);
        let rank = ranking(listMG)

        const getRang = (mark) => {
            return rank[listMG.indexOf(mark)]
        }       

        let column = [
            { 
                field: 'matière', 
                headerName: 'Matières',
                minWidth: 300,
                renderCell: (cellValues) => { return cellValues.row.subject.name}
            }
        ]

        let columnGroupingModel = [
            {
                groupId: 'RANG',
                description: '',
                children: [
                    { 
                        groupId: 'MOYENNE',
                        description: '',
                        children: [
                            { 
                                field: 'matière' 
                            }
                        ],
                    }
                ]    
            }
        ]

        if(subject.length > 0){
            columnGroupingModel = [
                {                    
                    groupId: 'RANG',
                    description: '',
                    children: [
                        { 
                            groupId: 'MOYENNE',
                            description: '',
                            children: [
                                { 
                                    groupId: 'Liste',
                                    description: '',
                                    children: [
                                        { 
                                            groupId: 'Étudiants',
                                            description: '',
                                            children: [
                                                    {field: 'matière' }
                                            ]
                                        }
                                    ]
                                }
                            ],
                        }
                    ]
                }
            ]

            subject[0].marks.map((element, index) => {                
                const b = { 
                    field: index.toString(), 
                    headerName:"Moy classe",  
                    minWidth: 100,
                    editable: true,
                    type: 'number',
                    renderCell: (cellValues) => { 
                        return cellValues.row.marks[index].moyen_classe
                    }
                }

                const c = { 
                    field: "b" + index.toString(), 
                    headerName: "Note compos",  
                    minWidth: 100,
                    editable: true,
                    type: 'number',
                    renderCell: (cellValues) => { return cellValues.row.marks[index].moyen_compo}
                }

                column = column.concat(b)
                column = column.concat(c)

                const cc = {
                    groupId: 'Rang ' + index.toString(),
                    headerName: getRang(listSTUDIENT.filter(c => c["student_id"] === element.student_id)[0].mg),
                    description: 'Rang',
                    headerAlign: 'center',
                    children: [
                        {
                            groupId: 'Moyenne ' + index.toString(),
                            headerName: (listSTUDIENT.filter(c => c["student_id"] === element.student_id)[0].mg).toFixed(2),
                            description: '',
                            headerAlign: 'center',
                            children: [
                                {
                                    groupId: index + 1,
                                    description: '',
                                    headerAlign: 'center',
                                    children: [
                                        {
                                            groupId: element.user.first_name + ' ' + element.user.second_name,
                                            description: element.user.matricule,
                                            headerAlign: 'center',
                                            children: [{ field: index.toString() }, { field: "b" + index.toString() }],

                                        }
                                    ],
                                }
                            ]
                        }
                    ]
                }

                columnGroupingModel = columnGroupingModel.concat(cc)
            }) 
        }      

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    <SelectClasse 
                        year_id={this.props.year_id} 
                        onChange={this.handleChangeClasse.bind(this)} 
                        width={200} 
                        mr={2} 
                    />
                    
                    <SelectComposition 
                        year_id={this.props.year_id} 
                        classe_id={classe_id} 
                        onChange={this.handleChangeComposition.bind(this)}
                        width={200}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12}  >
                    <Card elevation={6}  sx={{height:670, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={subjectsYears}
                            columns={column}
                            initialState={{ 
                                pinnedColumns: { 
                                    left: ['matière']
                                }
                            }}
                            experimentalFeatures={{ columnGrouping: true }}
                            columnGroupingModel={columnGroupingModel}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: { labelRowsPerPage: 'Ligne par page' }
                            }}
                            showCellVerticalBorder
                            showColumnVerticalBorder
                        />
                    </Card>
                </Grid>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}