import React, { Component } from "react";
import Grid from '@mui/material/Grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName, FirstLine, SecondLine, ThirdLine, FourthLine, FifthLine, SixthLine, SeventhLine, EighthLine} from '../../settings'
import Typography from '@mui/material/Typography';
import SelectClasse from '../SelectClasse'
import SelectComposition  from '../SelectComposition'
import Card from '@mui/material/Card';

export default class Gradebook extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notes: [],
            subjectsYears: [],
            students: [],

            classe_id: null,
            composition_id: null,

            show: false,

            anchorEl: [],   
            loading: true,
            severity: "",
            message: "",
            isAlert:false
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Bulletin de notes"
        this.setState({loading: false}) 
    }

    handleChangeClasse(data){
        if(data.name === 'classe_id'){
            this.setState({ classe_id: data.value }) 
        }        
    }

    handleChangeComposition(data){
        if(data.name === 'composition_id'){
            this.setState({ 
                composition_id: data.value,
                loading: true,
            }) 
            this.getData(data.value)
        }        
    }

    getData(id){ 
        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: this.state.classe_id,
            composition_id: id
        }
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/notes', 
            bodyParameters, 
            config
        )
        .then(response => {
            this.setState({ 
                notes: response.data.data,
                subjectsYears: response.data.subjectsYears,
                students: response.data.students,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    render() {   

        const {
            notes,
            subjectsYears,
            students,
            classe_id,
            show,
            loading,
            severity,
            message,
            isAlert,
        } = this.state

        let mark = []
        let markG = []
        let listMG = []
        students.map((student) => {
            let student_id = student.student_id   
            let subject_year_id = null
            let moyen_classe = 0
            let moyen_compo = 0
            let coef = 0

            let moyenG = 0
            let coefG = 0
            subjectsYears.map((element, index) => {
                let note = notes.filter(c => (c["subject_year_id"] === element.id &&  c["student_id"] === student_id))[0]
                subject_year_id = element.id
                moyen_classe = note.moyen_classe
                moyen_compo = note.moyen_compo
                coef = note.coef

                let item = {
                    student_id: student_id,
                    subject_year_id: subject_year_id,
                    coef: coef,
                    moyen_classe: moyen_classe,
                    moyen_compo: moyen_compo
                }

                mark = mark.concat(item)
                moyenG = moyenG + (coef * (moyen_classe + moyen_compo)/3)
                coefG = coefG + coef
            })

            let items = {
                student_id: student_id,
                moyenG: moyenG/coefG
            }
            markG = markG.concat(items)

            listMG = listMG.concat(moyenG/coefG)
        })

        const getNoteCl = (student_id, subject_year_id) => {
            let resultat = 0
            let item = mark.filter(c => (c["student_id"] === student_id &&  c["subject_year_id"] === subject_year_id))[0]
            resultat = item.moyen_classe
            return resultat
        }

        const getNoteCompo = (student_id, subject_year_id) => {
            let resultat = 0
            let item = mark.filter(c => (c["student_id"] === student_id &&  c["subject_year_id"] === subject_year_id))[0]
            resultat = item.moyen_compo
            return resultat
        }

        const getNoteCoef  = (student_id, subject_year_id) => {
            let resultat = 0
            let item = mark.filter(c => (c["student_id"] === student_id &&  c["subject_year_id"] === subject_year_id))[0]
            resultat = item.coef
            return resultat
        }

        const getNoteGen = (student_id, subject_year_id) => {
            return (getNoteCl(student_id, subject_year_id) + getNoteCompo(student_id, subject_year_id))/3
        }

        const getNotApreciation = (note) => {
            if(note >= 18){return "Excellent"}
            else if(note >= 16){return "Très Bien"}
            else if(note >= 14){return "Bien"}
            else if(note >= 12){return "Assez Bien"}
            else if(note >= 10){return "Passable"}
            else if(note >= 8){return "Insuffisant"}
            else if(note >= 6){return "Faible"}
            else if(note >= 4){return "Médiocre"}
            else if(note >= 2){return "Mal"}
            else if(note >= 0){return "Nul"}
        }

        const GetTotalCoef = (student_id) => {
            let resultat = 0
            subjectsYears.map((element, index) => {
                resultat = resultat + getNoteCoef(student_id, element.id)
            })
            return resultat
        }

        const GetTotalGen = (student_id) => {
            let resultat = 0
            subjectsYears.map((element, index) => {
                resultat = resultat + (getNoteGen(student_id, element.id) * getNoteCoef(student_id, element.id))
            })
            return resultat
        }

        const GetMoyenneGenere = (student_id) => {
            let resultat = 0
            resultat =  markG.filter(c => (c["student_id"] === student_id))[0].moyenG
            return resultat
        }

        const ranking = (arr) => arr.map((x, y, z) => z.filter((w) => w > x).length + 1);
        let rank = ranking(listMG)

        const getRang = (student_id) => {
            let resultat = 0
            resultat =  GetMoyenneGenere(student_id)
            resultat =  rank[listMG.indexOf(resultat)]
            return resultat
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    <SelectClasse 
                        year_id={this.props.year_id} 
                        onChange={this.handleChangeClasse.bind(this)} 
                        width={200} 
                        mr={2} 
                    />
                    
                    <SelectComposition 
                        year_id={this.props.year_id} 
                        classe_id={classe_id} 
                        onChange={this.handleChangeComposition.bind(this)}
                        width={200}
                    />                    
                </Grid>

                <Grid item xs={12} md={12} lg={12}>                
                    <Card elevation={6}  sx={{minHeight:570, backgroundColor:'#ffffff', borderRadius:2}}>
                        <Grid container spacing={3} p={2}>
                            {students.map((student, index) => (
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <div style={{width:'100%', border: '1px solid black'}}>                                    
                                        <div style={{width:'100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                            <div style={{ textAlign:'center', padding: 16}}>
                                                <Typography variant="body2" component="div" sx={{fontWeight:500}}>{FirstLine()}</Typography>
                                                <Typography variant="body2" component="div" sx={{fontWeight:500}}>*****</Typography>
                                                <Typography variant="body2" component="div" sx={{fontWeight:500}}>{SecondLine()}</Typography>
                                                <Typography variant="body2" component="div" sx={{fontWeight:500}}>{ThirdLine()}</Typography>
                                                <Typography variant="body2" component="div" sx={{fontWeight:500}}>*****</Typography>
                                                <Typography variant="body2" component="div" sx={{fontWeight:500}}>{FourthLine()}</Typography>
                                                <Typography variant="body2" component="div" sx={{fontWeight:500}}>{FifthLine()}</Typography>
                                                <Typography variant="body2" component="div" sx={{fontWeight:500}}>{SixthLine()}</Typography>
                                                <Typography variant="body2" component="div" sx={{fontWeight:500}}>{SeventhLine()}</Typography>
                                                <Typography variant="body2" component="div" sx={{fontWeight:500}}>{EighthLine()}</Typography>
                                            </div >

                                            <div style={{ textAlign:'center', padding: 16}}>
                                                <Typography variant="body2" component="div" sx={{fontWeight:500}}>REPUBLIQUE DU MALI</Typography>
                                                <Typography variant="body2" component="div" sx={{fontWeight:500}}>Un Peuple- Un But- Une Foi</Typography>
                                                <Typography variant="body2" component="div" sx={{fontWeight:500}}>*****</Typography>
                                            </div>                                        
                                        </div>

                                        <div style={{width:'100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                            <Typography variant="body2" component="div" sx={{fontWeight:600}}>Prénoms et NOM: {student.user.first_name + ' ' + student.user.second_name}</Typography>
                                            <Typography variant="body2" component="div" sx={{fontWeight:600}}>{index+1}</Typography>                                        
                                        </div>

                                        <div style={{backgroundColor:'yellow', width:'100%', textAlign:'center'}}>
                                            <Typography variant="body2" component="div" sx={{fontWeight:600}}>{student.classe.name}</Typography>
                                        </div>

                                        <div style={{width:'100%', textAlign:'center'}}>
                                            <Typography variant="body2" component="div" sx={{fontWeight:500}}>Bulletin du {notes[0].composition.name} {student.year.name}</Typography>
                                        </div>

                                        <table className="table table-hover" style={{width:"100%", textAlign:'left', marginTop:8}}>
                                            <thead> 
                                                <tr>
                                                    <th style={{border: '1px solid black', borderCollapse:'collapse' }}>Matières</th>
                                                    <th style={{border: '1px solid black', borderCollapse:'collapse' }}>N/20</th>
                                                    <th style={{border: '1px solid black', borderCollapse:'collapse' }}>N/40</th>
                                                    <th style={{border: '1px solid black', borderCollapse:'collapse' }}>M/Gén</th>
                                                    <th style={{border: '1px solid black', borderCollapse:'collapse' }}>Coéf.</th>
                                                    <th style={{border: '1px solid black', borderCollapse:'collapse' }}>M/Coéf.</th>
                                                    <th style={{border: '1px solid black', borderCollapse:'collapse' }}>Appréciations</th>
                                                </tr>
                                            </thead>

                                            <tbody>                                            
                                                {subjectsYears.map((element, index) => (
                                                    <tr>
                                                        <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{element.subject.name}</td>
                                                        <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{getNoteCl(student.student_id, element.id)}</td>
                                                        <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{getNoteCompo(student.student_id, element.id)}</td>
                                                        <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{getNoteGen(student.student_id, element.id).toFixed(2)}</td>
                                                        <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{getNoteCoef(student.student_id, element.id)}</td>
                                                        <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{(getNoteGen(student.student_id, element.id) * getNoteCoef(student.student_id, element.id)).toFixed(2)}</td>
                                                        <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{getNotApreciation(getNoteGen(student.student_id, element.id))}</td>
                                                    </tr>    
                                                ))}  

                                                <tr style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse' }}></td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}} colspan="3">Total</td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse' }}>{GetTotalCoef(student.student_id)}</td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}} colspan="2">{GetTotalGen(student.student_id).toFixed(2)}</td>
                                                </tr>  

                                                <tr style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse' }}>Moyenne du 1er :</td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}}>{Math.max(...listMG).toFixed(2)}</td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center' }} colspan="3">Moyenne</td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center' }} colspan="2">{GetMoyenneGenere(student.student_id).toFixed(2)}</td>
                                                </tr>  

                                                <tr style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse' }}></td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}} colspan="2">Rang</td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'right' }}>{getRang(student.student_id)}</td>
                                                    <td style={{textAlign:'left'}}>è  sur</td>
                                                    <td style={{textAlign:'left'}} colspan="2">{students.length.toLocaleString()} élèves </td>
                                                </tr>  

                                                <tr style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse' }}>Appréciation</td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}} colspan="3">{getNotApreciation(GetMoyenneGenere(student.student_id))}</td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'right' }} colspan="3"></td>
                                                </tr>     

                                                <tr style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500, height:21 }}>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse' }} colspan="7"> </td>
                                                </tr>  

                                                <tr style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500 }}>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center' }} colspan="2">Parent d'élève</td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}} colspan="2">Elève</td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center' }} colspan="3">Censeur</td>
                                                </tr>  

                                                <tr style={{border: '1px solid black', borderCollapse:'collapse', fontWeight: 500, height:168 }}>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center' }} colspan="2"> </td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center'}} colspan="2"> </td>
                                                    <td style={{border: '1px solid black', borderCollapse:'collapse', textAlign:'center' }} colspan="3"> </td>
                                                </tr> 
                                            </tbody>                                
                                        </table>    
                                    </div>
                                </Grid>    
                            ))}      
                        </Grid>
                    </Card>
                </Grid>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )
        
    }

}


