import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList'; 

export default class Rules extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rules: [],
            accesses: [],
            userss: [],

            dashbord: {read: false },
            parents: {read: false, write: false, approve: false},
            marks: {read: false, write: false},
            notes: {read: false},
            gradebooks: {read: false},
            gradebookYear: {read: false},
            propositionYear: {read: false},
            galerie: {read: false, write: false},
            announcement: {read: false, write: false, approve: false},
            fees: {read: false, write: false, remove: false},
            paiements: {read: false, write: false, remove: false},
            etats: {read: false },
            cycleYear: {read: false, write: false, approve: false},
            classeYear: {read: false, write: false, approve: false},
            studentYear: {read: false, write: false, remove: false},
            compositionYear: {read: false, write: false, approve: false},
            subjectYear: {read: false, write: false, remove: false},
            users: {read: false, write: false, approve: false},
            rule: {read: false, write: false, approve: false},
            cycles: {read: false, write: false, approve: false},
            classes: {read: false, write: false, approve: false},
            students: {read: false, write: false, approve: false},
            compositions: {read: false, write: false, approve: false},
            subjects: {read: false, write: false, approve: false},
            albums: {read: false, write: false, approve: false},
            accounts: {read: false, write: false, approve: false},
            logs: {read: false },

            add: false,
            edit: false,
            open: false,
            id: null,
            name:'',

            severity: "",
            isAlert:false,
            loading: true,

            anchorEl: [],   
            
            valuetab: "1"
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Rôles et permissions"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/rules', config)
        .then(response => {
            this.setState({ 
                rules: response.data.data,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    handleChangetab(event, newValue){ this.setState({ valuetab: newValue}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({loading: true})   

        const accesses = [
            {
                "module": "dashbord",
                "read": this.state.dashbord.read,
            },
            {
                "module": "parents",
                "read": this.state.parents.read,
                "write": this.state.parents.write,
                "approve": this.state.parents.approve
            },
            {
                "module": "marks",
                "read": this.state.marks.read,
                "write": this.state.marks.write,
            },
            {
                "module": "notes",
                "read": this.state.notes.read,
            },
            {
                "module": "gradebooks",
                "read": this.state.gradebooks.read,
            },
            {
                "module": "gradebookYear",
                "read": this.state.gradebookYear.read,
            },
            {
                "module": "propositionYear",
                "read": this.state.propositionYear.read,
            },
            {
                "module": "galerie",
                "read": this.state.galerie.read,
                "write": this.state.galerie.write,
            },
            {
                "module": "announcement",
                "read": this.state.announcement.read,
                "write": this.state.announcement.write,
                "approve": this.state.announcement.approve
            },
            {
                "module": "fees",
                "read": this.state.fees.read,
                "write": this.state.fees.write,
                "remove": this.state.fees.remove
            },
            {
                "module": "paiements",
                "read": this.state.paiements.read,
                "write": this.state.paiements.write,
                "remove": this.state.paiements.remove
            },
            {
                "module": "etats",
                "read": this.state.etats.read
            },
            {
                "module": "cycleYear",
                "read": this.state.cycleYear.read,
                "write": this.state.cycleYear.write,
                "approve": this.state.cycleYear.approve
            },
            {
                "module": "classeYear",
                "read": this.state.classeYear.read,
                "write": this.state.classeYear.write,
                "approve": this.state.classeYear.approve
            },
            {
                "module": "studentYear",
                "read": this.state.studentYear.read,
                "write": this.state.studentYear.write,
                "remove": this.state.studentYear.remove
            },
            {
                "module": "compositionYear",
                "read": this.state.compositionYear.read,
                "write": this.state.compositionYear.write,
                "approve": this.state.compositionYear.approve
            },
            {
                "module": "subjectYear",
                "read": this.state.subjectYear.read,
                "write": this.state.subjectYear.write,
                "remove": this.state.subjectYear.remove
            },
            {
                "module": "users",
                "read": this.state.users.read,
                "write": this.state.users.write,
                "approve": this.state.users.approve
            },
            {
                "module": "rule",
                "read": this.state.users.read,
                "write": this.state.users.write,
                "approve": this.state.users.approve
            },
            {
                "module": "cycles",
                "read": this.state.cycles.read,
                "write": this.state.cycles.write,
                "approve": this.state.cycles.approve
            },
            {
                "module": "classes",
                "read": this.state.classes.read,
                "write": this.state.classes.write,
                "approve": this.state.classes.approve
            },
            {
                "module": "students",
                "read": this.state.students.read,
                "write": this.state.students.write,
                "approve": this.state.students.approve
            },
            {
                "module": "compositions",
                "read": this.state.compositions.read,
                "write": this.state.compositions.write,
                "approve": this.state.compositions.approve
            },
            {
                "module": "subjects",
                "read": this.state.subjects.read,
                "write": this.state.subjects.write,
                "approve": this.state.subjects.approve
            },
            {
                "module": "albums",
                "read": this.state.albums.read,
                "write": this.state.albums.write,
                "approve": this.state.albums.approve
            },
            {
                "module": "accounts",
                "read": this.state.accounts.read,
                "write": this.state.accounts.write,
                "approve": this.state.accounts.approve
            },
            {
                "module": "logs",
                "read": this.state.logs.read
            }
        ]
            
        const bodyParameters = {
            name: this.state.name,
            accesses: JSON.stringify(accesses)
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/rules',
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode ===201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()
            }                       
        }).catch(error => {
            this.setState({
                add: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    handleSubmitEdit(event) {
        event.preventDefault()
        this.setState({loading: true})   

        const accesses = [
            {
                "module": "dashbord",
                "read": this.state.dashbord.read,
            },
            {
                "module": "parents",
                "read": this.state.parents.read,
                "write": this.state.parents.write,
                "approve": this.state.parents.approve
            },
            {
                "module": "marks",
                "read": this.state.marks.read,
                "write": this.state.marks.write,
            },
            {
                "module": "notes",
                "read": this.state.notes.read,
            },
            {
                "module": "gradebooks",
                "read": this.state.gradebooks.read,
            },
            {
                "module": "gradebookYear",
                "read": this.state.gradebookYear.read,
            },
            {
                "module": "propositionYear",
                "read": this.state.propositionYear.read,
            },
            {
                "module": "galerie",
                "read": this.state.galerie.read,
                "write": this.state.galerie.write,
            },
            {
                "module": "announcement",
                "read": this.state.announcement.read,
                "write": this.state.announcement.write,
                "approve": this.state.announcement.approve
            },
            {
                "module": "fees",
                "read": this.state.fees.read,
                "write": this.state.fees.write,
                "remove": this.state.fees.remove
            },
            {
                "module": "paiements",
                "read": this.state.paiements.read,
                "write": this.state.paiements.write,
                "remove": this.state.paiements.remove
            },
            {
                "module": "etats",
                "read": this.state.etats.read
            },
            {
                "module": "cycleYear",
                "read": this.state.cycleYear.read,
                "write": this.state.cycleYear.write,
                "approve": this.state.cycleYear.approve
            },
            {
                "module": "classeYear",
                "read": this.state.classeYear.read,
                "write": this.state.classeYear.write,
                "approve": this.state.classeYear.approve
            },
            {
                "module": "studentYear",
                "read": this.state.studentYear.read,
                "write": this.state.studentYear.write,
                "remove": this.state.studentYear.remove
            },
            {
                "module": "compositionYear",
                "read": this.state.compositionYear.read,
                "write": this.state.compositionYear.write,
                "approve": this.state.compositionYear.approve
            },
            {
                "module": "subjectYear",
                "read": this.state.subjectYear.read,
                "write": this.state.subjectYear.write,
                "remove": this.state.subjectYear.remove
            },
            {
                "module": "users",
                "read": this.state.users.read,
                "write": this.state.users.write,
                "approve": this.state.users.approve
            },
            {
                "module": "rule",
                "read": this.state.users.read,
                "write": this.state.users.write,
                "approve": this.state.users.approve
            },
            {
                "module": "cycles",
                "read": this.state.cycles.read,
                "write": this.state.cycles.write,
                "approve": this.state.cycles.approve
            },
            {
                "module": "classes",
                "read": this.state.classes.read,
                "write": this.state.classes.write,
                "approve": this.state.classes.approve
            },
            {
                "module": "students",
                "read": this.state.students.read,
                "write": this.state.students.write,
                "approve": this.state.students.approve
            },
            {
                "module": "compositions",
                "read": this.state.compositions.read,
                "write": this.state.compositions.write,
                "approve": this.state.compositions.approve
            },
            {
                "module": "subjects",
                "read": this.state.subjects.read,
                "write": this.state.subjects.write,
                "approve": this.state.subjects.approve
            },
            {
                "module": "albums",
                "read": this.state.albums.read,
                "write": this.state.albums.write,
                "approve": this.state.albums.approve
            },
            {
                "module": "accounts",
                "read": this.state.accounts.read,
                "write": this.state.accounts.write,
                "approve": this.state.accounts.approve
            },
            {
                "module": "logs",
                "read": this.state.logs.read
            }
        ]
            
        const bodyParameters = {
            name: this.state.name,
            accesses: JSON.stringify(accesses)
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.put(MainUrl() + '/api/v1/rule/' + this.state.id,
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode ===201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()
            }                       
        }).catch(error => {
            this.setState({
                edit: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    show(id){
        this.setState({loading: true})
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/rule/' + id, config)
        .then(response => {
            this.setState({ 
                name: response.data.rule.name,
                accesses: JSON.parse(response.data.rule.accesses),
                userss: response.data.userss,
                open: true,
                loading: false,
                anchorEl: [], 
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true,
                anchorEl: [], 
            }) 
        }); 

    }

    edit(id){
        this.setState({loading: true}) 
        const item = this.state.rules.filter(c => c["id"] === id)[0]
        const accesses = JSON.parse(item.accesses)
        this.setState({
            id: id,
            name: item.name,
            dashbord: {
                read: accesses[0].read 
            },
            parents: {
                read: accesses[1].read, 
                write: accesses[1].write, 
                approve: accesses[1].approve
            },
            marks: {
                read: accesses[2].read, 
                write: accesses[2].write, 
            },
            notes: {
                read: accesses[3].read
            },
            gradebooks: {
                read: accesses[4].read
            },
            gradebookYear: {
                read: accesses[5].read
            },
            propositionYear: {
                read: accesses[6].read
            },
            galerie: {
                read: accesses[7].read, 
                write: accesses[7].write,
            },
            announcement: {
                read: accesses[8].read, 
                write: accesses[8].write, 
                approve: accesses[8].approve
            },
            fees: {
                read: accesses[9].read, 
                write: accesses[9].write, 
                remove: accesses[9].remove
            },
            paiements: {
                read: accesses[10].read, 
                write: accesses[10].write, 
                remove: accesses[10].remove
            },
            etats: {
                read: accesses[11].read
            },
            cycleYear: {
                read: accesses[12].read, 
                write: accesses[12].write, 
                approve: accesses[12].approve
            },
            classeYear: {
                read: accesses[13].read, 
                write: accesses[13].write, 
                approve: accesses[13].approve
            },
            studentYear: {
                read: accesses[14].read, 
                write: accesses[14].write, 
                remove: accesses[14].remove
            },
            compositionYear: {
                read: accesses[15].read, 
                write: accesses[15].write, 
                approve: accesses[15].approve
            },
            subjectYear: {
                read: accesses[16].read, 
                write: accesses[16].write, 
                remove: accesses[16].remove
            },
            users: {
                read: accesses[17].read, 
                write: accesses[17].write, 
                approve: accesses[17].approve
            },
            rule: {
                read: accesses[18].read, 
                write: accesses[18].write, 
                approve: accesses[18].approve
            },
            cycles: {
                read: accesses[19].read, 
                write: accesses[19].write, 
                approve: accesses[19].approve
            },
            classes: {
                read: accesses[20].read, 
                write: accesses[20].write, 
                approve: accesses[20].approve
            },
            students: {
                read: accesses[21].read, 
                write: accesses[21].write, 
                approve: accesses[21].approve
            },
            compositions: {
                read: accesses[22].read, 
                write: accesses[22].write, 
                approve: accesses[22].approve
            },
            subjects: {
                read: accesses[23].read, 
                write: accesses[23].write, 
                approve: accesses[23].approve
            },
            albums: {
                read: accesses[24].read, 
                write: accesses[24].write, 
                approve: accesses[24].approve
            },
            accounts: {
                read: accesses[25].read, 
                write: accesses[25].write, 
                approve: accesses[25].approve
            },
            logs: {
                read: accesses[26].read
            },
            edit: true,
            anchorEl: [],   
            loading: false
        })
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/rule/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.componentDidMount()     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {

        const {
            rules,
            add,
            edit,
            open,
            name,
            accesses,
            userss,
            dashbord,
            parents,
            marks,
            notes,
            gradebooks,
            gradebookYear,
            propositionYear,
            galerie,
            announcement,
            fees,
            paiements,
            etats,
            cycleYear,
            classeYear,
            studentYear,
            compositionYear,
            subjectYear,
            users,
            rule,
            cycles,
            classes,
            students,
            compositions,
            subjects,
            albums,
            accounts,
            logs,

            loading,
            severity,
            message,
            isAlert,
            anchorEl,
            valuetab
        } = this.state

        const column = [
            { 
                field: 'name', 
                headerName: 'Nom',
                flex: 1/2
            },
            { 
                field: 'activated', 
                headerName: 'Statut',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    if(cellValues.row.activated) {
                        return (
                            <Tooltip title="Statut actif">
                                <IconButton onClick={() => this.delete(cellValues.row.id)}>   
                                    <Switch checked={true} />
                                </IconButton> 
                            </Tooltip>                            
                        )
                    }else{
                        return (
                            <Tooltip title="Statut non actif">
                                <IconButton onClick={() => this.delete(cellValues.row.id)}>   
                                    <Switch checked={false} />
                                </IconButton>
                            </Tooltip>
                        )
                    }                                       
                }
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                        width:150
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem  onClick={() => { this.show(cellValues.row.id) }}>
                                    <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                </MenuItem>

                                <MenuItem onClick={() => { this.edit(cellValues.row.id) }}>
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    )
                }
            }
        ]

        let DataRules=rules
        if(this.props.search !== ''){
            DataRules = DataRules.filter(elem => { return elem.name.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    <Button 
                        variant="contained" 
                        //disabled={!this.props.accesses.write} 
                        onClick={() => this.setState({add: true})} 
                        sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff'}}
                    >
                        Ajouter un rôle
                    </Button>    
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={DataRules}
                            columns={column}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Ligne par page'
                                }
                            }}
                        />
                    </Card>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                    scroll={'paper'}
                >
                    <DialogTitle id="scroll-dialog-title">Ajouter un rôle</DialogTitle>                    

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)}>
                        <DialogContent dividers={true}>
                            <DialogContentText tabIndex={-1} >
                                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Nom du rôle"
                                            name="name"
                                            style={{ width: '100%' }}
                                            value={name}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Sélectionner tout:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={
                                                    dashbord.read && parents.read && marks.read && notes.read && 
                                                    gradebooks.read && gradebookYear.read && propositionYear.read && galerie.read && 
                                                    announcement.read && fees.read && paiements.read && etats.read && 
                                                    etats.read && cycleYear.read && classeYear.read && studentYear.read &&
                                                    compositionYear.read && subjectYear.read && users.read && rule.read &&
                                                    cycles.read && classes.read && students.read && compositions.read &&
                                                    subjects.read && albums.read && accounts.read && logs.read
                                                }
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => {
                                                    parents.read = e.target.checked;
                                                    marks.read = e.target.checked;
                                                    notes.read = e.target.checked;
                                                    gradebooks.read = e.target.checked;
                                                    gradebookYear.read = e.target.checked;
                                                    propositionYear.read = e.target.checked;
                                                    galerie.read = e.target.checked;
                                                    announcement.read = e.target.checked;
                                                    fees.read = e.target.checked;
                                                    paiements.read = e.target.checked;
                                                    etats.read = e.target.checked;
                                                    cycleYear.read = e.target.checked;
                                                    classeYear.read = e.target.checked;
                                                    studentYear.read = e.target.checked;
                                                    compositionYear.read = e.target.checked;
                                                    subjectYear.read = e.target.checked;
                                                    users.read = e.target.checked;
                                                    rule.read = e.target.checked;
                                                    cycles.read = e.target.checked;
                                                    classes.read = e.target.checked;
                                                    students.read = e.target.checked;
                                                    compositions.read = e.target.checked;
                                                    subjects.read = e.target.checked;
                                                    albums.read = e.target.checked;
                                                    accounts.read = e.target.checked;
                                                    logs.read = e.target.checked;
                                                    this.setState({ 
                                                        dashbord: {read:e.target.checked}, parents, marks, notes, gradebooks, gradebookYear,
                                                        propositionYear, galerie, announcement, fees, paiements, etats, cycleYear, classeYear,
                                                        studentYear, compositionYear, subjectYear, users, rule, cycles, classes, students,
                                                        compositions, subjects, albums, accounts, logs
                                                    })
                                                }}
                                            /> 

                                            <FormControlLabel
                                                checked={
                                                    parents.write && marks.write &&
                                                    galerie.write && 
                                                    announcement.write && fees.write && paiements.write &&
                                                    cycleYear.write && classeYear.write && studentYear.write &&
                                                    compositionYear.write && subjectYear.write && users.write && rule.write &&
                                                    cycles.write && classes.write && students.write && compositions.write &&
                                                    subjects.write && albums.write && accounts.write
                                                }
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => {
                                                    parents.write = e.target.checked;
                                                    marks.write = e.target.checked;
                                                    galerie.write = e.target.checked;
                                                    announcement.write = e.target.checked;
                                                    fees.write = e.target.checked;
                                                    paiements.write = e.target.checked;
                                                    cycleYear.write = e.target.checked;
                                                    classeYear.write = e.target.checked;
                                                    studentYear.write = e.target.checked;
                                                    compositionYear.write = e.target.checked;
                                                    subjectYear.write = e.target.checked;
                                                    users.write = e.target.checked;
                                                    rule.write = e.target.checked;
                                                    cycles.write = e.target.checked;
                                                    classes.write = e.target.checked;
                                                    students.write = e.target.checked;
                                                    compositions.write = e.target.checked;
                                                    subjects.write = e.target.checked;
                                                    albums.write = e.target.checked;
                                                    accounts.write = e.target.checked;
                                                    this.setState({ 
                                                        parents, marks,
                                                        galerie, announcement, fees, paiements, cycleYear, classeYear,
                                                        studentYear, compositionYear, subjectYear, users, rule, cycles, classes, students,
                                                        compositions, subjects, albums, accounts
                                                    })
                                                }}
                                            /> 

                                            <FormControlLabel
                                                checked={
                                                    parents.approve && rule.approve && users.approve && compositionYear.approve &&
                                                    announcement.approve && cycleYear.approve && classeYear.approve &&                                                     compositionYear.approve && users.approve && rule.approve &&
                                                    cycles.approve && classes.approve && students.approve && compositions.approve &&
                                                    subjects.approve && albums.approve && accounts.approve
                                                }
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => {
                                                    parents.approve = e.target.checked;
                                                    announcement.approve = e.target.checked;
                                                    cycleYear.approve = e.target.checked;
                                                    classeYear.approve = e.target.checked;
                                                    compositionYear.approve = e.target.checked;
                                                    users.approve = e.target.checked;
                                                    rule.approve = e.target.checked;
                                                    cycles.approve = e.target.checked;
                                                    classes.approve = e.target.checked;
                                                    students.approve = e.target.checked;
                                                    compositions.approve = e.target.checked;
                                                    subjects.approve = e.target.checked;
                                                    albums.approve = e.target.checked;
                                                    accounts.approve = e.target.checked;
                                                    this.setState({ 
                                                        parents,
                                                        announcement, cycleYear, classeYear,
                                                        compositionYear, users, rule, cycles, classes, students,
                                                        compositions, subjects, albums, accounts
                                                    })
                                                }}
                                            />

                                            <FormControlLabel
                                                checked={
                                                    subjectYear.remove && fees.remove && paiements.remove && studentYear.remove
                                                }
                                                control={<Checkbox />}
                                                label="Suppression"
                                                labelPlacement="end"
                                                onChange={e => {
                                                    subjectYear.remove = e.target.checked;
                                                    fees.remove = e.target.checked;
                                                    paiements.remove = e.target.checked;
                                                    studentYear.remove = e.target.checked;
                                                    this.setState({ 
                                                        subjectYear, fees, paiements, studentYear
                                                    })
                                                }}
                                            /> 
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Dashboard:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={dashbord.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => this.setState({ dashbord: {read:e.target.checked} })}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Parents:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={parents.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { parents.read = e.target.checked; this.setState({ parents }) } }
                                            />

                                            <FormControlLabel
                                                checked={parents.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { parents.write = e.target.checked; this.setState({ parents }) } }
                                            />

                                            <FormControlLabel
                                                checked={parents.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { parents.approve = e.target.checked; this.setState({ parents }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Notes / Notes par matière:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={marks.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { marks.read = e.target.checked; this.setState({ marks }) } }
                                            />

                                            <FormControlLabel
                                                checked={marks.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { marks.write = e.target.checked; this.setState({ marks }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Notes / Notes:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={notes.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { notes.read = e.target.checked; this.setState({ notes }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Notes / Bulletin de notes:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={gradebooks.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { gradebooks.read = e.target.checked; this.setState({ gradebooks }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Notes / Bulletin annuel:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={gradebookYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { gradebookYear.read = e.target.checked; this.setState({ gradebookYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Notes / Propositions:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={propositionYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { propositionYear.read = e.target.checked; this.setState({ propositionYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Galerie:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={galerie.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { galerie.read = e.target.checked; this.setState({ galerie }) } }
                                            />

                                            <FormControlLabel
                                                checked={galerie.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { galerie.write = e.target.checked; this.setState({ galerie }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Annonces:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={announcement.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { announcement.read = e.target.checked; this.setState({ announcement }) } }
                                            />

                                            <FormControlLabel
                                                checked={announcement.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { announcement.write = e.target.checked; this.setState({ announcement }) } }
                                            />

                                            <FormControlLabel
                                                checked={announcement.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { announcement.approve = e.target.checked; this.setState({ announcement }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Comptabilité / Frais scolaire:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={fees.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { fees.read = e.target.checked; this.setState({ fees }) } }
                                            />

                                            <FormControlLabel
                                                checked={fees.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { fees.write = e.target.checked; this.setState({ fees }) } }
                                            />

                                            <FormControlLabel
                                                checked={fees.remove} 
                                                control={<Checkbox />}
                                                label="Suppression"
                                                labelPlacement="end"
                                                onChange={e => { fees.remove = e.target.checked; this.setState({ fees }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Comptabilité / Paiements:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={paiements.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { paiements.read = e.target.checked; this.setState({ paiements }) } }
                                            />

                                            <FormControlLabel
                                                checked={paiements.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { paiements.write = e.target.checked; this.setState({ paiements }) } }
                                            />

                                            <FormControlLabel
                                                checked={paiements.remove} 
                                                control={<Checkbox />}
                                                label="Suppression"
                                                labelPlacement="end"
                                                onChange={e => { paiements.remove = e.target.checked; this.setState({ paiements }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Comptabilité / États:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={etats.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => this.setState({ etats: {read:e.target.checked} })}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Administration / Cyles:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={cycleYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { cycleYear.read = e.target.checked; this.setState({ cycleYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={cycleYear.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { cycleYear.write = e.target.checked; this.setState({ cycleYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={cycleYear.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { cycleYear.approve = e.target.checked; this.setState({ cycleYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Administration / Classes:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={classeYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { classeYear.read = e.target.checked; this.setState({ classeYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={classeYear.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { classeYear.write = e.target.checked; this.setState({ classeYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={classeYear.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { classeYear.approve = e.target.checked; this.setState({ classeYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Administration / Inscriptions:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={studentYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { studentYear.read = e.target.checked; this.setState({ studentYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={studentYear.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { studentYear.write = e.target.checked; this.setState({ studentYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={studentYear.remove} 
                                                control={<Checkbox />}
                                                label="Suppression"
                                                labelPlacement="end"
                                                onChange={e => { studentYear.remove = e.target.checked; this.setState({ studentYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Administration / Compositions:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={compositionYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { compositionYear.read = e.target.checked; this.setState({ compositionYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={compositionYear.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { compositionYear.write = e.target.checked; this.setState({ compositionYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={compositionYear.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { compositionYear.approve = e.target.checked; this.setState({ compositionYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Administration / Matières:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={subjectYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { subjectYear.read = e.target.checked; this.setState({ subjectYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={subjectYear.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { subjectYear.write = e.target.checked; this.setState({ subjectYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={subjectYear.remove} 
                                                control={<Checkbox />}
                                                label="Suppression"
                                                labelPlacement="end"
                                                onChange={e => { subjectYear.remove = e.target.checked; this.setState({ subjectYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Utilisateurs:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={users.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { users.read = e.target.checked; this.setState({ users }) } }
                                            />

                                            <FormControlLabel
                                                checked={users.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { users.write = e.target.checked; this.setState({ users }) } }
                                            />

                                            <FormControlLabel
                                                checked={users.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { users.approve = e.target.checked; this.setState({ users }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Rôles:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={rule.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { rule.read = e.target.checked; this.setState({ rule }) } }
                                            />

                                            <FormControlLabel
                                                checked={rule.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { rule.write = e.target.checked; this.setState({ rule }) } }
                                            />

                                            <FormControlLabel
                                                checked={rule.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { rule.approve = e.target.checked; this.setState({ rule }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Cycles:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={cycles.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { cycles.read = e.target.checked; this.setState({ cycles }) } }
                                            />

                                            <FormControlLabel
                                                checked={cycles.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { cycles.write = e.target.checked; this.setState({ cycles }) } }
                                            />

                                            <FormControlLabel
                                                checked={cycles.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { cycles.approve = e.target.checked; this.setState({ cycles }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Classes:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={classes.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { classes.read = e.target.checked; this.setState({ classes }) } }
                                            />

                                            <FormControlLabel
                                                checked={classes.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { classes.write = e.target.checked; this.setState({ classes }) } }
                                            />

                                            <FormControlLabel
                                                checked={classes.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { classes.approve = e.target.checked; this.setState({ classes }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Étudiants:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={students.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { students.read = e.target.checked; this.setState({ students }) } }
                                            />

                                            <FormControlLabel
                                                checked={students.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { students.write = e.target.checked; this.setState({ students }) } }
                                            />

                                            <FormControlLabel
                                                checked={students.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { students.approve = e.target.checked; this.setState({ students }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Compositions:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={compositions.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { compositions.read = e.target.checked; this.setState({ compositions }) } }
                                            />

                                            <FormControlLabel
                                                checked={compositions.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { compositions.write = e.target.checked; this.setState({ compositions }) } }
                                            />

                                            <FormControlLabel
                                                checked={compositions.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { compositions.approve = e.target.checked; this.setState({ compositions }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Matières:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={subjects.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { subjects.read = e.target.checked; this.setState({ subjects }) } }
                                            />

                                            <FormControlLabel
                                                checked={subjects.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { subjects.write = e.target.checked; this.setState({ subjects }) } }
                                            />

                                            <FormControlLabel
                                                checked={subjects.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { subjects.approve = e.target.checked; this.setState({ subjects }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Albums:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={albums.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { albums.read = e.target.checked; this.setState({ albums }) } }
                                            />

                                            <FormControlLabel
                                                checked={albums.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { albums.write = e.target.checked; this.setState({ albums }) } }
                                            />

                                            <FormControlLabel
                                                checked={albums.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { albums.approve = e.target.checked; this.setState({ albums }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Types de frais:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={accounts.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { accounts.read = e.target.checked; this.setState({ accounts }) } }
                                            />

                                            <FormControlLabel
                                                checked={accounts.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { accounts.write = e.target.checked; this.setState({ accounts }) } }
                                            />

                                            <FormControlLabel
                                                checked={accounts.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { accounts.approve = e.target.checked; this.setState({ accounts }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Traces des activites:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={logs.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => this.setState({ logs: {read:e.target.checked} })}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({add: false})}>Annuller</Button>
                            <Button 
                                fullWidth 
                                type="submit" 
                                variant="contained" 
                            >
                                Cliquer pour ajouter
                            </Button>
                            {/* <Button  
                                type="submit" 
                                fullWidth
                                variant="contained" 
                                sx={{
                                    mt:2,
                                    color:"white", 
                                    textTransform: 'none', 
                                    fontSize:20, 
                                    height:'100%', 
                                    backgroundColor:'#888da0',  
                                    borderRadius:0, 
                                    ':hover':{backgroundColor:'#306bff'} 
                                }}
                            >
                                Cliquer pour ajouter
                            </Button>  */}
                        </DialogActions>

                          
                    </form>

                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                    scroll={'paper'}
                >
                    <DialogTitle id="scroll-dialog-title">Éditer un rôle</DialogTitle>                    

                    <form role="form"  onSubmit={this.handleSubmitEdit.bind(this)}>
                        <DialogContent dividers={true}>
                            <DialogContentText tabIndex={-1} >
                                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Nom du rôle"
                                            name="name"
                                            style={{ width: '100%' }}
                                            value={name}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Sélectionner tout:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={
                                                    dashbord.read && parents.read && marks.read && notes.read && 
                                                    gradebooks.read && gradebookYear.read && propositionYear.read && galerie.read && 
                                                    announcement.read && fees.read && paiements.read && etats.read && 
                                                    etats.read && cycleYear.read && classeYear.read && studentYear.read &&
                                                    compositionYear.read && subjectYear.read && users.read && rule.read &&
                                                    cycles.read && classes.read && students.read && compositions.read &&
                                                    subjects.read && albums.read && accounts.read && logs.read
                                                }
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => {
                                                    parents.read = e.target.checked;
                                                    marks.read = e.target.checked;
                                                    notes.read = e.target.checked;
                                                    gradebooks.read = e.target.checked;
                                                    gradebookYear.read = e.target.checked;
                                                    propositionYear.read = e.target.checked;
                                                    galerie.read = e.target.checked;
                                                    announcement.read = e.target.checked;
                                                    fees.read = e.target.checked;
                                                    paiements.read = e.target.checked;
                                                    etats.read = e.target.checked;
                                                    cycleYear.read = e.target.checked;
                                                    classeYear.read = e.target.checked;
                                                    studentYear.read = e.target.checked;
                                                    compositionYear.read = e.target.checked;
                                                    subjectYear.read = e.target.checked;
                                                    users.read = e.target.checked;
                                                    rule.read = e.target.checked;
                                                    cycles.read = e.target.checked;
                                                    classes.read = e.target.checked;
                                                    students.read = e.target.checked;
                                                    compositions.read = e.target.checked;
                                                    subjects.read = e.target.checked;
                                                    albums.read = e.target.checked;
                                                    accounts.read = e.target.checked;
                                                    logs.read = e.target.checked;
                                                    this.setState({ 
                                                        dashbord: {read:e.target.checked}, parents, marks, notes, gradebooks, gradebookYear,
                                                        propositionYear, galerie, announcement, fees, paiements, etats, cycleYear, classeYear,
                                                        studentYear, compositionYear, subjectYear, users, rule, cycles, classes, students,
                                                        compositions, subjects, albums, accounts, logs
                                                    })
                                                }}
                                            /> 

                                            <FormControlLabel
                                                checked={
                                                    parents.write && marks.write &&
                                                    galerie.write && 
                                                    announcement.write && fees.write && paiements.write &&
                                                    cycleYear.write && classeYear.write && studentYear.write &&
                                                    compositionYear.write && subjectYear.write && users.write && rule.write &&
                                                    cycles.write && classes.write && students.write && compositions.write &&
                                                    subjects.write && albums.write && accounts.write
                                                }
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => {
                                                    parents.write = e.target.checked;
                                                    marks.write = e.target.checked;
                                                    galerie.write = e.target.checked;
                                                    announcement.write = e.target.checked;
                                                    fees.write = e.target.checked;
                                                    paiements.write = e.target.checked;
                                                    cycleYear.write = e.target.checked;
                                                    classeYear.write = e.target.checked;
                                                    studentYear.write = e.target.checked;
                                                    compositionYear.write = e.target.checked;
                                                    subjectYear.write = e.target.checked;
                                                    users.write = e.target.checked;
                                                    rule.write = e.target.checked;
                                                    cycles.write = e.target.checked;
                                                    classes.write = e.target.checked;
                                                    students.write = e.target.checked;
                                                    compositions.write = e.target.checked;
                                                    subjects.write = e.target.checked;
                                                    albums.write = e.target.checked;
                                                    accounts.write = e.target.checked;
                                                    this.setState({ 
                                                        parents, marks,
                                                        galerie, announcement, fees, paiements, cycleYear, classeYear,
                                                        studentYear, compositionYear, subjectYear, users, rule, cycles, classes, students,
                                                        compositions, subjects, albums, accounts
                                                    })
                                                }}
                                            /> 

                                            <FormControlLabel
                                                checked={
                                                    parents.approve && rule.approve && users.approve && compositionYear.approve &&
                                                    announcement.approve && cycleYear.approve && classeYear.approve &&                                                     compositionYear.approve && users.approve && rule.approve &&
                                                    cycles.approve && classes.approve && students.approve && compositions.approve &&
                                                    subjects.approve && albums.approve && accounts.approve
                                                }
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => {
                                                    parents.approve = e.target.checked;
                                                    announcement.approve = e.target.checked;
                                                    cycleYear.approve = e.target.checked;
                                                    classeYear.approve = e.target.checked;
                                                    compositionYear.approve = e.target.checked;
                                                    users.approve = e.target.checked;
                                                    rule.approve = e.target.checked;
                                                    cycles.approve = e.target.checked;
                                                    classes.approve = e.target.checked;
                                                    students.approve = e.target.checked;
                                                    compositions.approve = e.target.checked;
                                                    subjects.approve = e.target.checked;
                                                    albums.approve = e.target.checked;
                                                    accounts.approve = e.target.checked;
                                                    this.setState({ 
                                                        parents,
                                                        announcement, cycleYear, classeYear,
                                                        compositionYear, users, rule, cycles, classes, students,
                                                        compositions, subjects, albums, accounts
                                                    })
                                                }}
                                            />

                                            <FormControlLabel
                                                checked={
                                                    subjectYear.remove && fees.remove && paiements.remove && studentYear.remove
                                                }
                                                control={<Checkbox />}
                                                label="Suppression"
                                                labelPlacement="end"
                                                onChange={e => {
                                                    subjectYear.remove = e.target.checked;
                                                    fees.remove = e.target.checked;
                                                    paiements.remove = e.target.checked;
                                                    studentYear.remove = e.target.checked;
                                                    this.setState({ 
                                                        subjectYear, fees, paiements, studentYear
                                                    })
                                                }}
                                            /> 
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Dashboard:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={dashbord.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => this.setState({ dashbord: {read:e.target.checked} })}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Parents:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={parents.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { parents.read = e.target.checked; this.setState({ parents }) } }
                                            />

                                            <FormControlLabel
                                                checked={parents.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { parents.write = e.target.checked; this.setState({ parents }) } }
                                            />

                                            <FormControlLabel
                                                checked={parents.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { parents.approve = e.target.checked; this.setState({ parents }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Notes / Notes par matière:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={marks.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { marks.read = e.target.checked; this.setState({ marks }) } }
                                            />

                                            <FormControlLabel
                                                checked={marks.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { marks.write = e.target.checked; this.setState({ marks }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Notes / Notes:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={notes.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { notes.read = e.target.checked; this.setState({ notes }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Notes / Bulletin de notes:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={gradebooks.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { gradebooks.read = e.target.checked; this.setState({ gradebooks }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Notes / Bulletin annuel:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={gradebookYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { gradebookYear.read = e.target.checked; this.setState({ gradebookYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Notes / Propositions:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={propositionYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { propositionYear.read = e.target.checked; this.setState({ propositionYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Galerie:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={galerie.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { galerie.read = e.target.checked; this.setState({ galerie }) } }
                                            />

                                            <FormControlLabel
                                                checked={galerie.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { galerie.write = e.target.checked; this.setState({ galerie }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Annonces:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={announcement.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { announcement.read = e.target.checked; this.setState({ announcement }) } }
                                            />

                                            <FormControlLabel
                                                checked={announcement.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { announcement.write = e.target.checked; this.setState({ announcement }) } }
                                            />

                                            <FormControlLabel
                                                checked={announcement.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { announcement.approve = e.target.checked; this.setState({ announcement }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Comptabilité / Frais scolaire:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={fees.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { fees.read = e.target.checked; this.setState({ fees }) } }
                                            />

                                            <FormControlLabel
                                                checked={fees.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { fees.write = e.target.checked; this.setState({ fees }) } }
                                            />

                                            <FormControlLabel
                                                checked={fees.remove} 
                                                control={<Checkbox />}
                                                label="Suppression"
                                                labelPlacement="end"
                                                onChange={e => { fees.remove = e.target.checked; this.setState({ fees }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Comptabilité / Paiements:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={paiements.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { paiements.read = e.target.checked; this.setState({ paiements }) } }
                                            />

                                            <FormControlLabel
                                                checked={paiements.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { paiements.write = e.target.checked; this.setState({ paiements }) } }
                                            />

                                            <FormControlLabel
                                                checked={paiements.remove} 
                                                control={<Checkbox />}
                                                label="Suppression"
                                                labelPlacement="end"
                                                onChange={e => { paiements.remove = e.target.checked; this.setState({ paiements }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Comptabilité / États:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={etats.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => this.setState({ etats: {read:e.target.checked} })}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Administration / Cyles:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={cycleYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { cycleYear.read = e.target.checked; this.setState({ cycleYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={cycleYear.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { cycleYear.write = e.target.checked; this.setState({ cycleYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={cycleYear.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { cycleYear.approve = e.target.checked; this.setState({ cycleYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Administration / Classes:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={classeYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { classeYear.read = e.target.checked; this.setState({ classeYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={classeYear.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { classeYear.write = e.target.checked; this.setState({ classeYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={classeYear.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { classeYear.approve = e.target.checked; this.setState({ classeYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Administration / Inscriptions:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={studentYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { studentYear.read = e.target.checked; this.setState({ studentYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={studentYear.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { studentYear.write = e.target.checked; this.setState({ studentYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={studentYear.remove} 
                                                control={<Checkbox />}
                                                label="Suppression"
                                                labelPlacement="end"
                                                onChange={e => { studentYear.remove = e.target.checked; this.setState({ studentYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Administration / Compositions:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={compositionYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { compositionYear.read = e.target.checked; this.setState({ compositionYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={compositionYear.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { compositionYear.write = e.target.checked; this.setState({ compositionYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={compositionYear.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { compositionYear.approve = e.target.checked; this.setState({ compositionYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Administration / Matières:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={subjectYear.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { subjectYear.read = e.target.checked; this.setState({ subjectYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={subjectYear.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { subjectYear.write = e.target.checked; this.setState({ subjectYear }) } }
                                            />

                                            <FormControlLabel
                                                checked={subjectYear.remove} 
                                                control={<Checkbox />}
                                                label="Suppression"
                                                labelPlacement="end"
                                                onChange={e => { subjectYear.remove = e.target.checked; this.setState({ subjectYear }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Utilisateurs:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={users.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { users.read = e.target.checked; this.setState({ users }) } }
                                            />

                                            <FormControlLabel
                                                checked={users.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { users.write = e.target.checked; this.setState({ users }) } }
                                            />

                                            <FormControlLabel
                                                checked={users.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { users.approve = e.target.checked; this.setState({ users }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Rôles:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={rule.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { rule.read = e.target.checked; this.setState({ rule }) } }
                                            />

                                            <FormControlLabel
                                                checked={rule.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { rule.write = e.target.checked; this.setState({ rule }) } }
                                            />

                                            <FormControlLabel
                                                checked={rule.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { rule.approve = e.target.checked; this.setState({ rule }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Cycles:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={cycles.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { cycles.read = e.target.checked; this.setState({ cycles }) } }
                                            />

                                            <FormControlLabel
                                                checked={cycles.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { cycles.write = e.target.checked; this.setState({ cycles }) } }
                                            />

                                            <FormControlLabel
                                                checked={cycles.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { cycles.approve = e.target.checked; this.setState({ cycles }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Classes:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={classes.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { classes.read = e.target.checked; this.setState({ classes }) } }
                                            />

                                            <FormControlLabel
                                                checked={classes.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { classes.write = e.target.checked; this.setState({ classes }) } }
                                            />

                                            <FormControlLabel
                                                checked={classes.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { classes.approve = e.target.checked; this.setState({ classes }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Étudiants:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={students.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { students.read = e.target.checked; this.setState({ students }) } }
                                            />

                                            <FormControlLabel
                                                checked={students.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { students.write = e.target.checked; this.setState({ students }) } }
                                            />

                                            <FormControlLabel
                                                checked={students.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { students.approve = e.target.checked; this.setState({ students }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Compositions:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={compositions.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { compositions.read = e.target.checked; this.setState({ compositions }) } }
                                            />

                                            <FormControlLabel
                                                checked={compositions.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { compositions.write = e.target.checked; this.setState({ compositions }) } }
                                            />

                                            <FormControlLabel
                                                checked={compositions.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { compositions.approve = e.target.checked; this.setState({ compositions }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Matières:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={subjects.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { subjects.read = e.target.checked; this.setState({ subjects }) } }
                                            />

                                            <FormControlLabel
                                                checked={subjects.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { subjects.write = e.target.checked; this.setState({ subjects }) } }
                                            />

                                            <FormControlLabel
                                                checked={subjects.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { subjects.approve = e.target.checked; this.setState({ subjects }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Albums:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={albums.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { albums.read = e.target.checked; this.setState({ albums }) } }
                                            />

                                            <FormControlLabel
                                                checked={albums.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { albums.write = e.target.checked; this.setState({ albums }) } }
                                            />

                                            <FormControlLabel
                                                checked={albums.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { albums.approve = e.target.checked; this.setState({ albums }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Paramètres / Types de frais:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={accounts.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => { accounts.read = e.target.checked; this.setState({ accounts }) } }
                                            />

                                            <FormControlLabel
                                                checked={accounts.write} 
                                                control={<Checkbox />}
                                                label="Écriture"
                                                labelPlacement="end"
                                                onChange={e => { accounts.write = e.target.checked; this.setState({ accounts }) } }
                                            />

                                            <FormControlLabel
                                                checked={accounts.approve} 
                                                control={<Checkbox />}
                                                label="Approuver"
                                                labelPlacement="end"
                                                onChange={e => { accounts.approve = e.target.checked; this.setState({ accounts }) } }
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >Traces des activites:</p>

                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                            <FormControlLabel
                                                checked={logs.read} 
                                                control={<Checkbox />}
                                                label="Lecture"
                                                labelPlacement="end"
                                                onChange={e => this.setState({ logs: {read:e.target.checked} })}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({edit: false})}>Annuller</Button>
                            <Button 
                                fullWidth 
                                type="submit" 
                                variant="contained" 
                            >
                                Cliquer pour modifier
                            </Button>
                        </DialogActions>                          
                    </form>

                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={open}
                    onClose={() => this.setState({open: false})}
                    scroll={'paper'}
                >
                    <DialogTitle id="scroll-dialog-title">{name}</DialogTitle>                    

                    <DialogContent dividers={true}>
                        <DialogContentText tabIndex={-1} >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TabContext value={valuetab}  >
                                        <Box sx={{  borderColor: 'divider'}}>
                                            <TabList onChange={this.handleChangetab.bind(this)} variant="scrollable" scrollButtons="auto">
                                                <Tab label="Rôle et permissions" value="1" />       
                                                <Tab label="Comptes associés" value="2" />
                                            </TabList>
                                        </Box>

                                        <TabPanel value="1" sx={{p:2, minHeight:"60vh"}}>
                                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                                {accesses.map((item) => (                                                    
                                                    <Grid item xs={12} md={3}>
                                                        <p style={{color:"#313339", fontSize:16, fontWeight:800}} >
                                                            {item.module === 'dashbord' && 'Dashboard:'}
                                                            {item.module === 'parents' && 'Parents:'}
                                                            {item.module === 'marks' && 'Notes / Notes par matière:'}
                                                            {item.module === 'notes' && 'Notes / Notes:'}
                                                            {item.module === 'gradebooks' && 'Notes / Bulletin de notes:'}
                                                            {item.module === 'gradebookYear' && 'Notes / Bulletin annuel:'}
                                                            {item.module === 'propositionYear' && 'Notes / Propositions:'}
                                                            {item.module === 'galerie' && 'Galerie:'}
                                                            {item.module === 'announcement' && 'Annonces:'}
                                                            {item.module === 'fees' && 'Comptabilité / Frais scolaire:'}
                                                            {item.module === 'paiements' && 'Comptabilité / Paiements:'}
                                                            {item.module === 'etats' && 'Comptabilité / États:'}
                                                            {item.module === 'cycleYear' && 'Administration / Cyles:'}
                                                            {item.module === 'classeYear' && 'Administration / Classes:'}
                                                            {item.module === 'studentYear' && 'Administration / Inscriptions:'}
                                                            {item.module === 'compositionYear' && 'Administration / Compositions:'}
                                                            {item.module === 'subjectYear' && 'Administration / Matières:'}
                                                            {item.module === 'users' && 'Utilisateurs:'}
                                                            {item.module === 'rule' && 'Paramètres / Rôles:'}
                                                            {item.module === 'cycles' && 'Paramètres / Cycles:'}
                                                            {item.module === 'classes' && 'Paramètres / Classes:'}
                                                            {item.module === 'students' && 'Paramètres / Étudiants:'}
                                                            {item.module === 'compositions' && 'Paramètres / Compositions:'}
                                                            {item.module === 'subjects' && 'Paramètres / Matières:'}
                                                            {item.module === 'albums' && 'Paramètres / Albums:'}
                                                            {item.module === 'accounts' && 'Paramètres / Types de frais:'}
                                                            {item.module === 'logs' && 'Traces des activites:'}
                                                        </p>

                                                        <FormGroup aria-label="position" row style={{marginTop: -16}}>
                                                            {item.read !== undefined &&
                                                                <FormControlLabel
                                                                    checked={item.read} 
                                                                    control={<Checkbox disabled />}
                                                                    label="Lecture"
                                                                    labelPlacement="end"
                                                                />
                                                            }  

                                                            {item.write !== undefined &&   
                                                                <FormControlLabel
                                                                    checked={item.write} 
                                                                    control={<Checkbox disabled />}
                                                                    label="Écriture"
                                                                    labelPlacement="end"
                                                                /> 
                                                            }

                                                            {item.approve !== undefined &&   
                                                                <FormControlLabel
                                                                    checked={item.approve} 
                                                                    control={<Checkbox disabled />}
                                                                    label="Approuver"
                                                                    labelPlacement="end"
                                                                />   
                                                            }

                                                            {item.remove !== undefined && 
                                                                <FormControlLabel
                                                                    checked={item.remove} 
                                                                    control={<Checkbox disabled />}
                                                                    label="Suppression"
                                                                    labelPlacement="end"
                                                                />
                                                            }
                                                        </FormGroup>
                                                    </Grid>
                                                ))}                                            
                                            </Grid>
                                        </TabPanel>

                                        <TabPanel value="2" sx={{p:2, minHeight:"60vh"}}>
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Matricule</th>
                                                        <th scope="col">Prénom</th>
                                                        <th scope="col">Nom</th>
                                                        <th scope="col">Rôle</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {userss.map((item) => {
                                                        return(
                                                            <tr>
                                                                <td>{item.matricule}</td>
                                                                <td>{item.first_name}</td>
                                                                <td>{item.second_name}</td>
                                                                <td>{item.rule.name}</td> 
                                                            </tr>
                                                        )
                                                    })} 
                                                </tbody>
                                            </table>
                                        </TabPanel>
                                    </TabContext>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button fullWidth onClick={() => this.setState({open: false})}>Fermer</Button>
                    </DialogActions>
                </Dialog>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}
