import React, { Component } from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings';
import SelectClasse from '../SelectClasse'
import SelectComposition  from '../SelectComposition'
import SelectSubject from '../SelectSubject'

export default class Marks extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notes: [],

            subject_id: null,
            classe_id: null,
            composition_id: null,
            subject_id: null,
            
            loading: true,
            severity: "",
            message: "",
            isAlert:false
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Notes par matière"
        this.setState({loading: false}) 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    handleChangeClasse(data){
        if(data.name === 'classe_id'){
            this.setState({ classe_id: data.value }) 
        }        
    }

    handleChangeComposition(data){
        if(data.name === 'composition_id'){
            this.setState({ composition_id: data.value })
        }        
    }

    handleChangeSubjectYear(data){
        if(data.name === 'subject_id'){
            this.setState({ 
                subject_id: data.value,
                loading: true,
            }) 
            this.getData(data.value)
        }        
    }

    getData(id){ 
        const bodyParameters = {
            year_id: this.props.year_id,
            classe_id: this.state.classe_id,
            composition_id: this.state.composition_id,
            subject_id: id
        }
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/note', 
            bodyParameters, 
            config
        )
        .then(response => {
            this.setState({ 
                notes: response.data.data,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    editTable(props){
        this.setState({ loading: true }) 
        
        const bodyParameters = {
            coef: props.coef,
            moyen_classe: props.moyen_classe,
            moyen_compo: props.moyen_compo
        }
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.put(MainUrl() + '/api/v1/note/' + props.id, 
            bodyParameters, 
            config
        )
        .then(response => {
            if(response.data.statusCode === 201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.getData(this.state.subject_id)        
            }               
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    render() {    

        const {
            notes,
            classe_id,
            composition_id,
            subject_id,
            loading,
            severity,
            message,
            isAlert,
        } = this.state        

        const column = [
            { 
                field: 'matricule', 
                headerName: 'Matricule',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.user.matricule}
            },
            { 
                field: 'sexe', 
                headerName: 'Sexe',
                maxWidth: 100,
                align:'center',
                renderCell: (cellValues) => { return <strong>{cellValues.row.student.sexe}</strong>}
            },
            { 
                field: 'prenom', 
                headerName: 'Prénom',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.user.first_name}
            },
            { 
                field: 'nom', 
                headerName: 'Nom',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.user.second_name}
            },
            { 
                field: 'coef', 
                headerName: 'Coéf.',
                minWidth: 150,
                type: 'number',
                editable: true
            },  
            { 
                field: 'moyen_classe', 
                headerName: 'Note Classe / 20',
                minWidth: 200,
                type: 'number',
                editable: true
            },  
            { 
                field: 'moyen_compo', 
                headerName: 'Note Compos / 40',
                minWidth: 200,
                type: 'number',
                editable: true,
            }
        ]        

        let note = notes
        if(this.props.search !== ''){
            let result = []
            note.map((elem, index) => {
                elem.search = elem.user.matricule + ' ' + elem.user.first_name + ' ' + elem.user.second_name + ' ' + elem.sexe + ' ' + elem.coef
                result = result.concat(elem)
            })
            note = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        return(
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    <SelectClasse 
                        year_id={this.props.year_id} 
                        onChange={this.handleChangeClasse.bind(this)} 
                        width={200} 
                        mr={2} 
                    />

                    <SelectComposition 
                        year_id={this.props.year_id} 
                        classe_id={classe_id} 
                        onChange={this.handleChangeComposition.bind(this)}
                        width={200}
                        mr={2} 
                    />
                    
                    <SelectSubject
                        year_id={this.props.year_id} 
                        classe_id={classe_id} 
                        composition_id={composition_id}
                        onChange={this.handleChangeSubjectYear.bind(this)}
                        width={200}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={note}
                            columns={column}
                            editMode="row"
                            processRowUpdate={(updatedRow, originalRow) => this.editTable(updatedRow)}
                            onProcessRowUpdateError={() => {}}
                            elevation={10}
                            initialState={{ 
                                pinnedColumns: { 
                                    left: ['matière', 'sexe', 'prenom', 'nom']
                                }
                            }}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: { labelRowsPerPage: 'Ligne par page' }
                            }}
                        />
                    </Card>
                </Grid>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}