export function MainUrl() {
    return 'https://api.gesco.school'
    //return 'http://127.0.0.1:8000'
}

export function SchoolName() {
    return 'GESTION SCOLAIRE'
}

export function FirstLine() {
    return "MINISTERE DE L'EDUCATION NATIONALE"
}

export function SecondLine() {
    return "DIRECTION NATIONALE DE"
}

export function ThirdLine() {
    return "L'ENSEIGNEMENT SECONDAIRE GENERAL"
}

export function FourthLine() {
    return "ACADEMIE D'ENSEIGNEMENT DE KATI"
}

export function FifthLine() {
    return "LYCEE PRIVE Ahmed HAIDARA"
}

export function SixthLine() {
    return "SOTUBA ACI - RUE: 2020 PORTE: 20"
}

export function SeventhLine() {
    return "Tél. : (223) 70 69 03 95 / 70 69 03 95"
}

export function EighthLine() {
    return "Email : hello.haidara@gmail.com"
}
