import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default class Users extends Component {

    constructor(props) {
        super(props)
        this.state = {
            users: [],
            rules: [],

            add: false,
            edit: false,
            id: null,
            matricule: '',
            first_name:'',
            second_name: '',
            rule_id: "",
            password:"",
            passwordc: "",

            showPassword: false,
            showPasswordc: false,

            severity: "",
            message: "",
            isAlert:false,
            loading: true,   

            anchorEl: [],               
        }
    }
    
    componentDidMount(){
        document.title = SchoolName() + " | Utilisateurs scolaires"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/users', config)
        .then(response => {
            this.setState({ 
                users: response.data.users,
                rules: response.data.rules,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleSubmit(event) {
        event.preventDefault()
        if(this.state.password !== this.state.passwordc){
            this.setState({ 
                loading: false,
                severity: 'error',
                message: "Les deux mots de passe ne sont pas identiques",
                isAlert: true
            })
        }else{
            this.setState({loading: true})   
                
            const bodyParameters = {
                first_name: this.state.first_name,
                second_name: this.state.second_name,
                rule_id: this.state.rule_id,
                matricule: this.state.matricule,
                password: this.state.password
            }

            const token = JSON.parse(localStorage.getItem("TOKEN"))
            const config = {
                headers: {'Authorization': 'Bearer '+token}
            }
            axios.post(MainUrl() + '/api/v1/users',
                bodyParameters,
                config
            ).then(response => {
                if(response.data.statusCode === 201){
                    this.setState({
                        loading: false,
                        severity: 'error',
                        message: response.data.message,
                        isAlert: true
                    }) 
                } else{
                    this.setState({
                        add: false,
                        loading: false,
                        severity: 'success',
                        message: response.data.message,
                        isAlert: true
                    }) 
                    this.componentDidMount()            }                       
            }).catch(error => {
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'error',
                    message: error.response.data?.message || "Une erreur inattendue s'est produite",
                    isAlert: true
                });
            });
        }
    }

    handleSubmitEdit(event){
        event.preventDefault()
        if(this.state.password !== this.state.passwordc){
            this.setState({ 
                loading: false,
                severity: 'error',
                message: "Les deux mots de passe ne sont pas identiques",
                isAlert: true
            })
        }else{
            this.setState({loading: true})   
                
            const bodyParameters = {                
                first_name: this.state.first_name,
                second_name: this.state.second_name,
                rule_id: this.state.rule_id,
                matricule: this.state.matricule,
                password: this.state.password
            }

            const token = JSON.parse(localStorage.getItem("TOKEN"))
            const config = {
                headers: {'Authorization': 'Bearer '+token}
            }

            axios.put(MainUrl() + '/api/v1/user/' + this.state.id,
                bodyParameters,
                config
            ).then(response => {
                if(response.data.statusCode === 201){
                    this.setState({
                        loading: false,
                        severity: 'error',
                        message: response.data.message,
                        isAlert: true
                    }) 
                } else{
                    this.setState({
                        edit: false,
                        loading: false,
                        severity: 'success',
                        message: response.data.message,
                        isAlert: true
                    }) 
                    this.componentDidMount()            }                       
            }).catch(error => {
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'error',
                    message: error.response.data?.message || "Une erreur inattendue s'est produite",
                    isAlert: true
                });
            });
        }
    }

    edit(id){
        const item = this.state.users.filter(c => c["id"] === id)[0]
        this.setState({
            id: id,            
            first_name: item.first_name,
            second_name: item.second_name,
            rule_id: item.rule_id,
            matricule: item.matricule,
            password:"",
            passwordc: "",
            edit: true,
            anchorEl: [],   
        })
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/user/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.componentDidMount()     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {        

        const {
            users,
            rules,
            add,
            edit,
            matricule,
            first_name,
            second_name,
            rule_id,
            password,
            passwordc,
            showPassword,
            showPasswordc,
            loading,
            severity,
            message,
            isAlert,
            anchorEl
        } = this.state

        const column = [
            { 
                field: 'matricule', 
                headerName: 'Matricule',
                flex: 1
            },
            { 
                field: 'first_name', 
                headerName: 'Prénom',
                flex: 1
            },
            { 
                field: 'second_name', 
                headerName: 'Nom',
                flex: 1
            },
            { 
                field: 'rule', 
                headerName: 'Rôle',
                flex: 1,
                renderCell: (cellValues) => { return cellValues.row.rule.name}
            },
            { 
                field: 'is_activated', 
                headerName: 'Statut',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    if(cellValues.row.is_activated) {
                        return (
                            <Tooltip title="Statut actif">
                                <IconButton onClick={() => this.delete(cellValues.row.id)}>   
                                    <Switch checked={true} />
                                </IconButton> 
                            </Tooltip>                            
                        )
                    }else{
                        return (
                            <Tooltip title="Statut non actif">
                                <IconButton onClick={() => this.delete(cellValues.row.id)}>   
                                    <Switch checked={false} />
                                </IconButton>
                            </Tooltip>
                        )
                    }                                       
                }
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                        width:150
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={() => { this.edit(cellValues.row.id) }}>
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    )
                }
            }
        ]

        let Datausers=users
        // if(status == 'Tout'){
        //     Dataregisters = registers
        // }
        // if(status == 'Actif'){
        //     Dataregisters = registers.filter(c => c["archived"] === false)
        // }
        // if(status == 'Non actif'){
        //     Dataregisters = registers.filter(c => c["archived"] === true)
        // }
        if(this.props.search !== ''){
            let result = []
            Datausers.map((elem, index) => {
                elem.search = elem.matricule + ' ' + elem.first_name + ' ' + elem.second_name + ' ' + elem.rule.name
                result = result.concat(elem)
            })
            Datausers = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    <Button 
                        variant="contained" 
                        //disabled={!this.props.accesses.write} 
                        onClick={() => this.setState({add: true})} 
                        sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff'}}
                    >
                        Ajouter un utilisateur
                    </Button>    
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={Datausers}
                            columns={column}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Ligne par page'
                                }
                            }}
                        />
                    </Card>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter un utilisateur
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Matricule"
                                        name="matricule"
                                        style={{ width: '100%' }}
                                        value={matricule}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Rôle"
                                        name="rule_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                    >
                                        {rules.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Prénom"
                                        name="first_name"
                                        style={{ width: '100%' }}
                                        value={first_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de famille"
                                        name="second_name"
                                        style={{ width: '100%' }}
                                        value={second_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="password"
                                        label="Nouveau mot de passe"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPassword:! showPassword})} 
                                                    >
                                                        {showPassword ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="passwordc"
                                        label="Mot de passe confirmation"
                                        type={showPasswordc ? 'text' : 'password'}
                                        value={passwordc}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPasswordc:! showPasswordc})} 
                                                    >
                                                        {showPasswordc ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>   
                    </form>

                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Éditer un user scolaire
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmitEdit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Matricule"
                                        name="matricule"
                                        style={{ width: '100%' }}
                                        value={matricule}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Rôle"
                                        name="rule_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={rule_id}
                                    >
                                        {rules.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Prénom"
                                        name="first_name"
                                        style={{ width: '100%' }}
                                        value={first_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de famille"
                                        name="second_name"
                                        style={{ width: '100%' }}
                                        value={second_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="password"
                                        label="Nouveau mot de passe"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPassword:! showPassword})} 
                                                    >
                                                        {showPassword ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="passwordc"
                                        label="Mot de passe confirmation"
                                        type={showPasswordc ? 'text' : 'password'}
                                        value={passwordc}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPasswordc:! showPasswordc})} 
                                                    >
                                                        {showPasswordc ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>
                
                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }
    
}