import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import bgLogin from "../../assets/bg-login.jpeg"
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import AlertNotification from '../AlertNotification'
import {MainUrl, SchoolName} from '../../settings'

export default class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            matricule: "",
            password : "" ,
            message: "",

            severity: "",
            isAlert:false,
            loading: false,       
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Login"
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true })

        const matricule = this.state.matricule
        const password = this.state.password

        axios({
            method: 'post',
            responseType: 'json',
            url:  MainUrl() + '/api/v1/login',
            data: {
                matricule,
                password
            }
        }).then(res => { 
            const user = res.data.data        
            if(res.data.message === "Accès temporairement refusé"){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: res.data.message,
                    isAlert: true
                }) 
            }else{    
                this.setState({
                    loading: false,
                    severity: 'success',
                    message: res.data.message,
                    isAlert: true
                }) 
                localStorage.setItem("USER", JSON.stringify(user));
                localStorage.setItem("TOKEN", JSON.stringify(user.token));
                //     localStorage.setItem("ROLE", JSON.stringify(res.data.user.role));
                setTimeout(() => {
                    window.location.href = '/dashboard';                
                }, 250);
            }
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error,
                isAlert: true
            }) 
        });
    } 

    isAlertChange(){ this.setState({isAlert:false}) }

    render() {

        const {
            matricule,
            password,
            loading,
            severity,
            message,
            isAlert
        } = this.state

        return (
            <Grid container component="main" sx={{ height: '100vh' }}>                    
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={9}
                    sx={{
                        backgroundImage: `url(${bgLogin})`,
                        backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'left',
                    }}
                />

                <Grid item xs={12} sm={8} md={3} component={Paper} elevation={6} square>                        
                    <Box
                        sx={{
                            height: '100vh',
                            // mt: 24,
                            mx: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography component="h1" variant="h4" color="text.secondary" sx={{fontWeight: 800}}>{SchoolName()}</Typography>

                        <Typography component="h1" variant="body1" color="text.secondary" sx={{ mt: 4, textAlign:'left' }}>Vous devez vous connecter pour continuer.</Typography>
                        
                        <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop: 32}}>
                            <TextField
                                required
                                fullWidth
                                label="Votre matricule"
                                name="matricule"
                                size="small"
                                onChange={this.handleChange.bind(this)}
                                value={matricule}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Votre mot de passe"
                                type="password"
                                size="small"
                                onChange={this.handleChange.bind(this)}
                                value={password}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                elevation={10}
                                sx={{mt:3, height:40, boxShadow: 10, borderRadius:2, bgcolor: '#306bff' }}
                                endIcon={ loading ? <CircularProgress size={24} ml={2} sx={{color:'white'}} value={100} /> : null}
                                disabled={loading || (matricule==="" || password==="")}
                            >
                                Connexion
                            </Button>
                        </form>

                        <Typography color="text.secondary" sx={{fontSize:16 }} mt={2}><strong>Login:</strong>RC2024A000</Typography>

                        <Typography color="text.secondary" sx={{fontSize:16 }}><strong>Mot de passe:</strong>admin@123</Typography>                        
                    </Box>
                </Grid>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>
            </Grid>         
        )
        
    }

}