import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './components/App';
import "bootstrap/dist/css/bootstrap.min.css";

import { createTheme, ThemeProvider } from '@mui/material/styles';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={defaultTheme}>
           <App /> 
        </ThemeProvider>        
    </React.StrictMode>, 

    document.getElementById('root')  
);
