import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Dropzone from 'react-dropzone'
import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';


export default class Tuteurs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tuteurs: [],
            students: [],
            studentToadd: [],

            add: false,
            edit: false,
            show: false,
            addstudent: false,
            id: null,
            user_id: null,
            student_id: null,
            matricule: "",
            first_name: "",
            second_name: "",

            password:"",
            passwordc: "",
            showPassword: false,
            showPasswordc: false,

            severity: "",
            message: "",
            isAlert:false,
            loading: true,     

            anchorEl: []
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Parents"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/tuteurs', config)
        .then(response => {
            this.setState({ 
                tuteurs: response.data.data,
                students: response.data.students,
                studentToadd: response.data.studentToadd,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleSubmit(event) {
        event.preventDefault()
        if(this.state.password !== this.state.passwordc){
            this.setState({ 
                loading: false,
                severity: 'error',
                message: "Les deux mots de passe ne sont pas identiques",
                isAlert: true
            })
        }else{
            this.setState({loading: true})   

            const bodyParameters = {
                matricule: this.state.matricule,
                first_name: this.state.first_name,
                second_name: this.state.second_name,
                password: this.state.password
            }

            const token = JSON.parse(localStorage.getItem("TOKEN"))
            const config = {
                headers: {'Authorization': 'Bearer '+token}
            }
            axios.post(MainUrl() + '/api/v1/tuteurs',
                bodyParameters,
                config
            ).then(response => {
                if(response.data.statusCode === 201){
                    this.setState({
                        loading: false,
                        severity: 'error',
                        message: response.data.message,
                        isAlert: true
                    }) 
                } else{
                    this.setState({
                        add: false,
                        loading: false,
                        severity: 'success',
                        message: response.data.message,
                        isAlert: true
                    }) 
                    this.componentDidMount()           
                }                       
            }).catch(error => {
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'error',
                    message: error.response.data?.message || "Une erreur inattendue s'est produite",
                    isAlert: true
                });
            });
        }
    }

    handleSubmitEdit(event){
        event.preventDefault()
        if(this.state.password !== this.state.passwordc){
            this.setState({ 
                loading: false,
                severity: 'error',
                message: "Les deux mots de passe ne sont pas identiques",
                isAlert: true
            })
        }else{
            this.setState({loading: true})   
                
            const bodyParameters = {
                user_id: this.state.user_id,
                matricule: this.state.matricule,
                first_name: this.state.first_name,
                second_name: this.state.second_name,
                password: this.state.password
            }

            const token = JSON.parse(localStorage.getItem("TOKEN"))
            const config = {
                headers: {'Authorization': 'Bearer '+token}
            }

            axios.put(MainUrl() + '/api/v1/tuteur/' + this.state.id,
                bodyParameters,
                config
            ).then(response => {
                if(response.data.statusCode === 201){
                    this.setState({
                        loading: false,
                        severity: 'error',
                        message: response.data.message,
                        isAlert: true
                    }) 
                } else{
                    this.setState({
                        edit: false,
                        loading: false,
                        severity: 'success',
                        message: response.data.message,
                        isAlert: true
                    }) 
                    this.componentDidMount()            }                       
            }).catch(error => {
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'error',
                    message: error.response.data?.message || "Une erreur inattendue s'est produite",
                    isAlert: true
                });
            });
        }
    }

    handleSubmitAdStudent(event) {
        event.preventDefault()
        
        this.setState({loading: true})   

        const bodyParameters = {
            student_id: this.state.student_id
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.put(MainUrl() + '/api/v1/tuteur-student/' + this.state.id,
            bodyParameters,
            config
        ).then(response => {
            this.setState({
                addstudent: false,
                loading: false,
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.componentDidMount()
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    show(id){
        const item = this.state.tuteurs.filter(c => c["id"] === id)[0]

        this.setState({
            id: id,
            matricule: item.user.matricule,
            first_name: item.user.first_name,
            second_name: item.user.second_name,
            show: true,
            anchorEl: [],   
        })
    }

    edit(id){
        const item = this.state.tuteurs.filter(c => c["id"] === id)[0]

        this.setState({
            id: id,
            user_id: item.user_id,
            matricule: item.user.matricule,
            first_name: item.user.first_name,
            second_name: item.user.second_name,
            password:"",
            passwordc: "",
            edit: true,
            anchorEl: [],   
        })
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/tuteur/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.componentDidMount()     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    deleteCompte(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/tuteur-student/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.componentDidMount()     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {    

        const {
            tuteurs,
            students,
            studentToadd,
            add,
            edit,
            show,
            addstudent,
            id,
            matricule,
            first_name,
            second_name,
            password,
            passwordc,
            showPassword,
            showPasswordc,
            loading,
            severity,
            message,
            isAlert,
            anchorEl,
        } = this.state

        const column = [
            { 
                field: 'matricule', 
                headerName: 'Matricule',
                flex: 1/2,
                renderCell: (cellValues) => { return cellValues.row.user.matricule}
            },
            { 
                field: 'first_name', 
                headerName: 'Prénom',
                flex: 1/2,
                renderCell: (cellValues) => { return cellValues.row.user.first_name}
            },
            { 
                field: 'second_name', 
                headerName: 'Nom de famille',
                flex: 1/2,
                renderCell: (cellValues) => { return cellValues.row.user.second_name}
            },
            { 
                field: 'activated', 
                headerName: 'Statut',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    if(cellValues.row.activated) {
                        return (
                            <Tooltip title="Statut actif">
                                <IconButton onClick={() => this.delete(cellValues.row.id)}>   
                                    <Switch checked={true} />
                                </IconButton> 
                            </Tooltip>                            
                        )
                    }else{
                        return (
                            <Tooltip title="Statut non actif">
                                <IconButton onClick={() => this.delete(cellValues.row.id)}>   
                                    <Switch checked={false} />
                                </IconButton>
                            </Tooltip>
                        )
                    }                                       
                }
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                        width:150
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem  onClick={() => { this.show(cellValues.row.id) }}>
                                    <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                </MenuItem>

                                <MenuItem onClick={() => { this.edit(cellValues.row.id) }}>
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    )
                }
            }
        ]

        let DataTuteurs=tuteurs

        if(this.props.search !== ''){
            let result = []
            DataTuteurs.map((elem, index) => {
                elem.search = elem.user.matricule + ' ' + elem.user.first_name + ' ' + elem.user.second_name
                result = result.concat(elem)
            })
            DataTuteurs = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        let studentAssocie = []
        if(id !== null){
            studentAssocie = students.filter(c => c["tuteur_id"] === id)
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    <Button 
                        variant="contained" 
                        //disabled={!this.props.accesses.write} 
                        onClick={() => this.setState({add: true})} 
                        sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff'}}
                    >
                        Ajouter un parent
                    </Button>    
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:620, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={DataTuteurs}
                            columns={column}
                            loading={loading}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Ligne par page'
                                }
                            }}
                        />
                    </Card>
                </Grid> 

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter un parent
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Matricule"
                                        name="matricule"
                                        style={{ width: '100%' }}
                                        value={matricule}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Prénom"
                                        name="first_name"
                                        style={{ width: '100%' }}
                                        value={first_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de famille"
                                        name="second_name"
                                        style={{ width: '100%' }}
                                        value={second_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="password"
                                        label="Nouveau mot de passe"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPassword:! showPassword})} 
                                                    >
                                                        {showPassword ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="passwordc"
                                        label="Mot de passe confirmation"
                                        type={showPasswordc ? 'text' : 'password'}
                                        value={passwordc}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPasswordc:! showPasswordc})} 
                                                    >
                                                        {showPasswordc ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Éditer les données d'un parent
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmitEdit.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Matricule"
                                        name="matricule"
                                        style={{ width: '100%' }}
                                        value={matricule}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Prénom"
                                        name="first_name"
                                        style={{ width: '100%' }}
                                        value={first_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de famille"
                                        name="second_name"
                                        style={{ width: '100%' }}
                                        value={second_name}
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="password"
                                        label="Nouveau mot de passe"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPassword:! showPassword})} 
                                                    >
                                                        {showPassword ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        name="passwordc"
                                        label="Mot de passe confirmation"
                                        type={showPasswordc ? 'text' : 'password'}
                                        value={passwordc}
                                        autoComplete="current-password"
                                        placeholder="Admin2022$"
                                        onChange={this.handleChange.bind(this)}
                                        style={{ width: '100%' }}
                                        size="small"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => this.setState({ showPasswordc:! showPasswordc})} 
                                                    >
                                                        {showPasswordc ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                    </IconButton>
                                                </InputAdornment>                                        
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>
                
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={show}
                    onClose={() => this.setState({show: false})}
                    scroll={'paper'}
                >
                    <DialogTitle>{'[' + matricule + ']< < < < < ' + first_name + '< < ' + second_name}</DialogTitle> 

                    <DialogContent dividers={true}>
                        <DialogContentText tabIndex={-1} >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>    
                                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between'}} >
                                        <Typography color="text.secondary" sx={{fontSize:18, fontWeight:700 }}><u>Comptes étudiants associés</u></Typography>

                                        <Button 
                                            variant="contained" 
                                            //disabled={!this.props.accesses.write} 
                                            onClick={() => this.setState({addstudent: true})} 
                                            sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff'}}
                                        >
                                            Associé un compte d'étudiant
                                        </Button>
                                    </Box>                               
                                    
                                    <table className="table table-hover" style={{marginTop:16}}>
                                        <thead>
                                            <tr>
                                                <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Matricule</Typography></th>
                                                <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Prénom</Typography></th>
                                                <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Nom</Typography></th>
                                                <th scope="col" style={{textAlign:'center'}} ><Typography color="text.secondary" sx={{fontSize:16 }}>Actions</Typography></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {studentAssocie.map((item) => {
                                                return(
                                                    <tr>
                                                        <td><Typography color="text.secondary" sx={{fontSize:16 }}>{item.user.matricule}</Typography></td>
                                                        <td><Typography color="text.secondary" sx={{fontSize:16 }}>{item.user.first_name}</Typography></td>
                                                        <td><Typography color="text.secondary" sx={{fontSize:16 }}>{item.user.second_name}</Typography></td>
                                                        <td style={{textAlign:'center'}}>
                                                            <Button
                                                                onClick={() => {if(window.confirm('Êtes-vous sûr de le dissocier ?')){this.deleteCompte(item.id)}}}
                                                                variant="contained"
                                                                sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'red', color:'white'}}}
                                                            >
                                                                Supprimer
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })} 
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.setState({show: false})}>Fermer</Button>
                    </DialogActions>
                </Dialog> 
                
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={addstudent}
                    onClose={() => this.setState({addstudent: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Associé un compte d'étudiant
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addstudent: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form"  onSubmit={this.handleSubmitAdStudent.bind(this)} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>                                
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        select
                                        label="Étudiant(e)"
                                        name="student_id"
                                        onChange={this.handleChange.bind(this)}
                                        size="small"
                                        style={{ width: '100%' }}
                                    >
                                        {studentToadd.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.user.matricule} {option.user.first_name} {option.user.second_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>                                
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>   
                    </form>
                </Dialog>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )


    }

}