import React, { Component } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import {MainUrl, SchoolName} from '../settings'

export default class SelectYear extends Component {

    constructor(props) {
        super(props);
        this.state = {
            years: [],
            year_id: null,
            anchorEl: null,
        }
    }

    componentDidMount(){
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/years', config)
        .then(response => {
            let years = response.data.data            
            years = years.filter(c => c["activated"] === 1)
            this.setState({ 
                years: years,
                year_id: years[0].id,
            });
            this.selectYear(years[0].id)
        }).catch(error => {
            
        }); 
    }

    handleClick(event){
        this.setState({anchorEl: event.currentTarget})
    }

    selectYear(id){
        this.setState(
            {
                year_id:  id,
                anchorEl: null
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(id)
                }
            }        
        )
    }

    render() {

        const {
            years,
            year_id,
            anchorEl
        } = this.state

        let  name = ""
        if(years.filter(c => c["id"] === year_id)[0] !== undefined){
            name = years.filter(c => c["id"] === year_id)[0].name
        }

        return (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography >Année académique: <span style={{color:'#306bff'}}>{name}</span></Typography>

                <IconButton onClick={this.handleClick.bind(this)} sx={{marginLeft: 0.2}}>
                    <ExpandMoreIcon sx={{color:'#306bff'}}/>
                </IconButton>

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => {this.setState({ anchorEl: null})}}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                    PaperProps={{
                        sx: {
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            // mt: 2,
                            // ml: -2
                            // padding:1
                        }
                    }}
                >
                    {years.map((elem) => (
                        <MenuItem 
                            onClick={() =>this.selectYear(elem.id)} 
                            sx={year_id === elem.id ? {color:'#306bff'} : null}
                        >
                            Année Académique: {elem.name}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        )

    }
}
