import React, { Component } from "react";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import {MainUrl} from '../settings'
import TextField from '@mui/material/TextField';

export default class SelectSubject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subjectYears: [],
            subject_id: null
        }
    }

    componentDidMount(){
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.get(MainUrl() + '/api/v1/subject-years', 
            config
        )
        .then(response => {
            this.setState({ 
                subjectYears: response.data.data,
                subjects: response.data.subjects,
            });
        }).catch(error => { }); 
    } 

    handleChange(e) {
        const { name, value } = e.target;
        this.setState(
            { [name]: value },
            () => {
                if (this.props.onChange) {
                    const data = {
                        name: 'subject_id',
                        value: e.target.value
                    }
                    this.props.onChange(data)
                }                 
            }      
        );
    }

    render() {

        const {subjectYears,  subject_id} = this.state
        
        let subjectYear= []
        if(this.props.year_id !== null){
            subjectYear = subjectYears.filter(c => c["year_id"] === this.props.year_id)
        }
        if(this.props.classe_id !== null){
            subjectYear = subjectYear.filter(c => c["classe_id"] === this.props.classe_id)
        }
        if(this.props.composition_id !== null){
            subjectYear = subjectYear.filter(c => c["composition_id"] === this.props.composition_id)
        }

        return (
            <Box sx={{display: 'flex', alignItems: 'center', ...this.props}}>
                <TextField
                    disabled={this.props.classe_id === null || this.props.composition_id === null}
                    select
                    label="Matières"
                    name="subject_id"
                    onChange={this.handleChange.bind(this)}
                    size="small"
                    style={{ width: '100%' }}
                    value={subject_id || ''}
                >
                    {subjectYear.map((option) => (
                        <MenuItem key={option.id} value={option.subject_id}>
                            {option.subject.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
        )

    }
}
